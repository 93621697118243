import * as React from 'react';
import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';

import { constants } from 'common/constants';
import { Role } from 'pages/settings/types';
import { getUser, hasPermission } from 'common/helpers/utils';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import { CustomToolbarWithOnlySearch } from 'common/components/CustomToolbarWithOnlySearch';
import Box from '@mui/material/Box';

type RolesTableProps = {
  data: Role[];
  handleAddUser: (role: Role) => void;
};

const RolesTable: React.FC<RolesTableProps> = (props) => {
  const [rows, setRows] = React.useState<Role[]>(props.data);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const addUser = React.useCallback(
    (gridRow: any) => () => {
      props.handleAddUser(gridRow.row);
    },
    [props],
  );

  const hasAddUserPermission = (roleName: string) => {
    const loggedInUserRole = getUser()?.roleName;
    if (!hasPermission('users.summary', 'write')) {
      return true;
    }
    if (constants.SUPER_ADMIN_ROLE_PATTERN.test(loggedInUserRole)) {
      return false;
    } else if (constants.ADMIN_ROLE_PATTERN.test(loggedInUserRole)) {
      return constants.ADMIN_SUPER_ADMIN_ROLE_PATTERN.test(roleName);
    }
    return true;
  };

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        type: 'string',
        headerName: 'Role Name',
        flex: 1,
        maxWidth: 150,
      },
      {
        field: 'description',
        type: 'string',
        headerName: 'Description',
        flex: 1,
        display: 'flex',
        renderCell: (params) => (
          <Box
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}>
            {params.value}
          </Box>
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Add User"
            disabled={hasAddUserPermission(params.row.name)}
            onClick={addUser(params)}
            showInMenu
            key="addUser"
          />,
        ],
      },
    ],
    [addUser],
  );

  return (
    <DataGrid
      columns={columns}
      rows={hasPermission('roles', 'read') ? rows : []}
      getRowId={(row) => row.roleId}
      pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
      initialState={{
        pagination: { paginationModel: constants.PAGINATION_MODEL },
      }}
      autoHeight
      slots={{
        toolbar: CustomToolbarWithOnlySearch,
        noRowsOverlay: () => (
          <NoRowsOverlay
            hasAccess={hasPermission('roles', 'read')}
            name="Roles"
          />
        ),
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '600',
        },
      }}
    />
  );
};

export default RolesTable;
