import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';

import {
  CssBaseline,
  Card,
  Drawer,
  Link,
  Button,
  CardHeader,
  Box,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { Device, DeviceFormMasterData } from '../types';
import AddDevice from './AddDevice';
import DeleteDevice from './DeleteDevice';
import DevicesTable from './DevicesTable';
import EditDevice from './EditDevice';
import { useContentStyles } from 'common/styles/useContentStyles';
import {
  getAccessWindowDisplaySetting,
  getTimezone,
  hasPermission,
  isEndUser,
} from 'common/helpers/utils';
import { DrawerHeader } from 'common/styles/styledComponents';
import AddManufacturerDialog from './AddManufacturerDialog';
import { constants } from 'common/constants';
import { RootState } from 'redux/Store';

export const deviceDefaultValues = {
  siteId: '',
  name: '',
  alias: '',
  deviceId: '',
  manufacturerId: '',
  type: '',
  typeId: '',
  groups: [],
  siteName: '',
  manufacturerName: '',
  status: '',
  inboundIpAddr: '',
  domainRegistered: 'No',
};
const Devices: React.FC<{ isHideBreadCrumb?: boolean; siteId?: string }> = ({
  isHideBreadCrumb,
  siteId,
}) => {
  const classes = useContentStyles();
  const timerRef = useRef<number>();
  const { userData } = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();
  const TIMEOUT = 1000;
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [selectedDevice, setSelectedDevice] =
    useState<Device>(deviceDefaultValues);
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [devices, setDevices] = useState<Device[]>([]);
  const [hasDevices, setHasDevices] = useState(false);
  const [deviceFormMasterData, setDeviceFormMasterData] =
    useState<DeviceFormMasterData>({
      accessMethods: [],
      deviceTypes: [],
      groups: [],
      manufacturers: [],
      sites: [],
      owners: [],
      operators: [],
    });
  const isRunEffect = useRef(true);
  const navigate = useNavigate();
  const timezone = useMemo(() => getTimezone(), []);
  const getDeviceFormMasterData = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const api = siteId
        ? `devices/device-form-look-up-data?siteId=${siteId}`
        : `devices/device-form-look-up-data`;
      const formMasterResponse = await apiClient.get(api);
      const formMasters = formMasterResponse.data.data as DeviceFormMasterData;
      setDeviceFormMasterData(formMasters);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch, siteId]);
  const location = useLocation();

  React.useEffect(() => {
    if (isRunEffect.current) {
      getDeviceFormMasterData();
      if (location.state?.isAddDeviceGroup) {
        setShowAddGroupForm(true);
      }
      isRunEffect.current = false;
    }
  }, [getDeviceFormMasterData, isRunEffect, location.state]);

  const updateTable = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const portalType = process.env.REACT_APP_PORTAL_TYPE ?? 'ALL';
      const api = siteId
        ? `/devices?siteId=${siteId}&portalType=${portalType}&timezone=${timezone}`
        : `/devices?portalType=${portalType}&timezone=${timezone}`;
      const devicesResponse = await apiClient.get(api);
      setDevices(devicesResponse.data.data.devices as Device[]);
      setHasDevices(devicesResponse.data.data.hasDevices);
      setDeviceFormMasterData((prevState) => ({
        ...prevState,
        devices: devicesResponse.data.data.devices,
      }));
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch, siteId, timezone]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
    return () => clearTimeout(timerRef.current);
  }, [isUpdate, updateTable]);

  const openAddForm = () => {
    setOpenForm(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
  };
  const onCloseEdit = () => {
    setEdit(false);
  };

  const onSaveUpdateTable = () => {
    timerRef.current = window.setTimeout(() => {
      setEdit(false);
      setOpenForm(false);
      setUpdate(true);
    }, TIMEOUT);
  };

  const onDeleteDevice = async () => {
    try {
      if (selectedDevice?.deviceId) {
        const devicesResponse = await apiClient.delete(
          `/devices/${selectedDevice.deviceId}`,
        );
        dispatch(
          setSnackbarToast({
            message: devicesResponse.data.meta.message,
            open: true,
            severity: 'success',
          }),
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
      setOpenDialogue(false);
    }
  };
  const handleDelete = (device: Device) => {
    setSelectedDevice(device);
    setOpenDialogue(true);
  };
  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };
  const onCancelRemove = () => {
    setOpenDialogue(false);
  };
  const onEditDevice = async (device: Device) => {
    if (device.connectionProfiles && device.connectionProfiles.length > 0) {
      //fetch connection profiles with decrypted password
      try {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          }),
        );
        const detailsResponse = await apiClient.post(
          `devices/connectionProfiles`,
          device.connectionProfiles,
        );
        setSelectedDevice({
          ...device,
          connectionProfiles: detailsResponse.data.data,
        });
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: 'error',
          }),
        );
      }
    } else {
      setSelectedDevice(device);
    }
    setEdit(true);
  };

  const [showAddGroupForm, setShowAddGroupForm] = React.useState(false);
  const openAddGroupForm = () => {
    setShowAddGroupForm(true);
  };

  const hideGroupForm = () => {
    setShowAddGroupForm(false);
    //if user is from groups table then navigate to groups page
    if (location.state?.isAddDeviceGroup) {
      navigate(`/portal/devices/groups`);
    }
  };

  const navigateToGroups = () => {
    setShowAddGroupForm(false);
    navigate(`/portal/devices/groups`);
  };

  const [openAddManufacturerDialogue, setOpenAddManufacturerDialogue] =
    React.useState(false);
  const getManufacturers = async () => {
    try {
      const manufacturersResponse = await apiClient.get('/manufacturers');
      setDeviceFormMasterData((prevState) => ({
        ...prevState,
        manufacturers: manufacturersResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    setOpenAddManufacturerDialogue(false);
  };

  const handleFavoriteDevice = async (device: Device) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      if (device.isFavorite) {
        await apiClient.delete(`users/favoriteDevice/${device.deviceId}`);
      } else {
        await apiClient.put(`users/favoriteDevice/${device.deviceId}`);
      }
      const portalType = process.env.REACT_APP_PORTAL_TYPE ?? 'ALL';

      const api = siteId
        ? `/devices?siteId=${siteId}&portalType=${portalType}&timezone=${timezone}`
        : `/devices?portalType=${portalType}&timezone=${timezone}`;
      const devicesResponse = await apiClient.get(api);
      setDevices(devicesResponse.data.data.devices as Device[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  };

  const fetchDevices = useCallback(
    async (isShowLoader?: boolean) => {
      try {
        isShowLoader &&
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: true,
            }),
          );
        const portalType = process.env.REACT_APP_PORTAL_TYPE ?? 'ALL';
        const devicesApi = siteId
          ? `devices?siteId=${siteId}&portalType=${portalType}&timezone=${timezone}`
          : `devices?portalType=${portalType}&timezone=${timezone}`;
        const devicesResponse = await apiClient.get(devicesApi);
        setHasDevices(devicesResponse.data.data.hasDevices);
        // hide Gateway devices from EndUser role
        setDevices(
          isEndUser()
            ? devicesResponse.data.data.devices.filter(
                (dev: Device) =>
                  dev.type.toLowerCase() !== constants.DEVICE_TYPE_GATEWAY,
              )
            : devicesResponse.data.data.devices,
        );
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
      }
    },
    [dispatch, siteId, timezone],
  );

  useEffect(() => {
    fetchDevices(true);
    let intervalId: NodeJS.Timer;
    if (isEndUser()) {
      intervalId = setInterval(fetchDevices, 5 * 60 * 1000);
    }
    return () => clearInterval(intervalId);
  }, [dispatch, fetchDevices]);

  return (
    <React.Fragment>
      <CssBaseline />
      {!isHideBreadCrumb && (
        <div className={classes.contentPadding}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="none" color="inherit">
              Devices
            </Link>
          </Breadcrumbs>
        </div>
      )}
      <Card
        elevation={0}
        className={isHideBreadCrumb ? '' : classes.contentSection}>
        {!showAddGroupForm ? (
          <CardHeader
            disableTypography
            title="Devices"
            className={classes.cardHeader}
            action={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: (theme) => theme.palette.info.main,
                }}>
                {isEndUser() && (
                  <Box
                    sx={{
                      paddingRight: 1,
                    }}>
                    <Typography>
                      Times displayed in:{' '}
                      <strong>
                        {
                          getAccessWindowDisplaySetting(
                            userData.accessWindowDisplay,
                          ).display
                        }
                      </strong>
                    </Typography>
                  </Box>
                )}
                {!isEndUser() && hasPermission('devices.summary', 'write') && (
                  <Button
                    onClick={openAddForm}
                    color="info"
                    startIcon={<PlaylistAddIcon />}>
                    {'Add Device'}
                  </Button>
                )}
                {!isEndUser() && hasPermission('devices.groups', 'write') && (
                  <Button
                    onClick={openAddGroupForm}
                    color="info"
                    startIcon={<PlaylistAddIcon />}>
                    {'Create Group'}
                  </Button>
                )}
              </Box>
            }></CardHeader>
        ) : (
          <CardHeader
            disableTypography
            title="Devices"
            className={classes.cardHeader}></CardHeader>
        )}
        <Drawer
          anchor="right"
          open={openForm}
          classes={{ paper: classes.formPaper }}>
          <DrawerHeader></DrawerHeader>
          <AddDevice
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseForm}
            deviceFormMasterData={deviceFormMasterData}
            openAddManufacturerForm={() =>
              setOpenAddManufacturerDialogue(true)
            }></AddDevice>
        </Drawer>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}>
          <DrawerHeader />
          <EditDevice
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseEdit}
            selectedDevice={selectedDevice}
            deviceFormMasterData={deviceFormMasterData}
            openAddManufacturerForm={() =>
              setOpenAddManufacturerDialogue(true)
            }></EditDevice>
        </Drawer>
        {openDialogue && (
          <DeleteDevice
            onDeleteDevice={onDeleteDevice}
            selectedDevice={selectedDevice}
            open={openDialogue}
            onCancelRemove={onCancelRemove}></DeleteDevice>
        )}
        {openAddManufacturerDialogue && (
          <AddManufacturerDialog
            open={openAddManufacturerDialogue}
            onCancel={() => setOpenAddManufacturerDialogue(false)}
            onSave={getManufacturers}></AddManufacturerDialog>
        )}
        <DevicesTable
          data={devices}
          hasDevices={hasDevices}
          handleEditDevice={onEditDevice}
          handleDeleteDevice={handleDelete}
          showAddGroupForm={showAddGroupForm}
          deviceFormMasterData={deviceFormMasterData}
          hideGroupForm={hideGroupForm}
          navigateToGroups={navigateToGroups}
          handleFavoriteDevice={handleFavoriteDevice}
          refreshDevicesList={() => fetchDevices(true)}></DevicesTable>
      </Card>
    </React.Fragment>
  );
};

export default Devices;
