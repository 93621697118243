import { DayType } from 'pages/trust/types';
import { HostnameType, IdentityAccessProvider, RuleTemplate } from './enums';

export const constants = {
  TIMEOUT: 1000,
  PASSWORD_a_z_REGEX: /[a-z]+/,
  PASSWORD_A_Z_REGEX: /[A-Z]+/,
  PASSWORD_0_9_REGEX: /\d.*\d/,
  PASSWORD_SPECIAL_CHAR_REGEX: /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]+/,
  DIRECTORY_USERS_PATH: '/directory/users',
  DIRECTORY_GROUPS_PATH: '/directory/groups',
  SNACKBAR_AUTO_HIDE_TIME: 3000,
  PASSWORD_REGEX:
    /^(?=[\w!#$%&*+/<=>?@\\^~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+/<=>?@\\^_~])(?=.{8,}).*$/,
  EMAIL_REGEX:
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/,
  GROUP_NAME_REGEX: /^[\w '#$&+./@-]{1,100}$/, //device and user group names
  NAME_REGEX: /^[\w '#$&+./@-\s]{1,100}$/, //device name, org name, username
  SITE_NAME_REGEX: /^[\w-]{1,100}$/, //allowed only chars and numbers and _-
  USER_NAME_REGEX: /^[A-Za-z0-9-'_@']{1,100}$/, //first name, last name
  ZIP_CODE_REGEX: /^\d{5}(?:-\d{4})?$|^\d{6}$/, ///^[0-9]{5}(?:-[0-9]{4})?|[0-9]{6}$/,
  IP_REGEX: /^(\d{1,3}\.){3}\d{1,3}(\/(1[6-9]|2\d|3[0-2]))$/,
  NLB_IP_REGEX: /^(\d{1,3}\.){3}\d{1,3}$/,
  FQDN_REGEX:
    /^(([\dA-Za-z]|[\dA-Za-z][\dA-Za-z-]*[\dA-Za-z])\.)*([\dA-Za-z]|[\dA-Za-z][\dA-Za-z-]*[\dA-Za-z])$/,
  HOSTING_NAME_REGEX: /^(?!(aws|azure|gcp|salesforce|ibm)).*/i,

  HTTPS_URL_REGEX_: /^(https):\/\/[^ "]+$/,
  LATITUDE_REGEX: /^(-?([1-8]?[0-9](\.\d{1,9})?|90(\.0{1,9})?))$/,
  LONGITUDE_REGEX:
    /^(-?((180(\.0{1,9})?)|((1[0-7][0-9]|[0-9]{1,2})(\.\d{1,9})?)))$/,
  HEX_COLOR_CODE_REGEX: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  MAC_ADDRESS_REGEX: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
  IP_ADDRESS_REGEX:
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  MAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  PHONE_REGEX: /^(?:\d{10}|\d{3}\s?\d{3}\s?\d{4})$/,
  NUMERIC_VALUE_REGEX: /^(?:\d*\.\d+|\d+\.\d*|\d+)$/,
  HOME_PAGE_PATH: '/portal/sites/summary',
  DEVICE_TYPE_GATEWAY: 'gateway',
  DAYS: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ] as DayType[],
  WEEK_DAYS: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  DAYS_TEMPLATE: [
    RuleTemplate.ALL_DAYS,
    RuleTemplate.WEEK_DAYS,
    RuleTemplate.CUSTOM,
  ],
  TIME_SELECTION_TYPE: [RuleTemplate.CUSTOM, RuleTemplate.FULL_DAY_TIME],
  PAGINATION_MODEL: { pageSize: 15 },
  PAGE_SIZE_OPTIONS: [15, 25, 50, 100],
  ACCESS_PROFILES: ['Admin', 'EndUser'],
  POWER_PRODUCTION_UNIT: 'kW',
  DRAWER_WIDTH: 250,
  LOGOUT_PATH: '/login',
  URL_REGEX: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
  ACCESS_PROVIDERS: [IdentityAccessProvider.AZURE, IdentityAccessProvider.OKTA],
  LOGIN_PATH: '/login',
  AZURE_LOGIN_PATH: '/azure-login',
  DYNAMIC_GROUP_CONFIRM_MESSAGE:
    'You are creating a dynamic group – is this what you want?',
  STATIC_GROUP_CONFIRM_MESSAGE:
    'You are creating a static group – is this what you want?',
  DYNAMIC_GROUP_UPDATE_CONFIRM_MESSAGE:
    'You are updating a dynamic group – is this what you want?',
  STATIC_GROUP_UPDATE_CONFIRM_MESSAGE:
    'You are updating a static group – is this what you want?',
  ADMIN_ROLE_ID: '1',
  SUPER_ADMIN_ROLE_ID: '3',
  END_USER_ROLE_ID: '4',
  ADMIN_ROLE_NAME: 'admin',
  END_USER_ROLE_NAME: 'enduser',
  SETTINGS_ROUTE_STATE: 'settings',
  END_USER_ACCESS_DENIED_MENUS: [
    'users',
    'trust',
    'logging',
    'viz',
    'settings',
    'sites.groups',
    'devices.groups',
  ],
  ADMIN_END_USER_ROLE_PATTERN: /\b(?:admin|enduser|superadmin)\b/i,
  END_USER_ROLE_PATTERN: /^end[-_\s]?user$/i,
  ADMIN_SUPER_ADMIN_ROLE_PATTERN: /\b(?:admin|superadmin)\b/i,
  MANAGER_ROLE_PATTERN: /^manager\b.*$/i,
  SUPER_ADMIN_ROLE_PATTERN: /^super[-_\s]?admin$/i,
  ADMIN_ROLE_PATTERN: /^admin$/i,
  MANAGER_CONSOLE_ROLE_PATTERN: /^manager[-_\s]?console$/i,
  MANAGER_RULES_ROLE_PATTERN: /^manager[-_\s]?rules$/i,
  MANAGER_REVIEW_ROLE_PATTERN: /^manager[-_\s]?review$/i,
  AZURE_USER_DEFAULT_PASSWORD: '@TEST1234',
  ALL_MENUS: [
    'users',
    'trust',
    'logging',
    'viz',
    'settings',
    'sites',
    'devices',
  ],
  END_USER_ACCESS_FEATURES: [
    'sites.summary',
    'sites.groups',
    'devices.summary',
    'devices.groups',
    'devices.remoteAccess',
  ],
  ROLE_NAME_REGEX:
    /^(?:(?!(?:Admin|SuperAdmin|EndUser)\b)[\w '#$&+./@-\s]){1,100}$/i,
  GATEWAY_DEVICE_TYPE_PATTERN: /\bgateway\b/i,
  DEVICE_PORT_PATTERN: /^\d{1,5}$/,
  REPEAT_EVENT_PATTERN: /^\d{1,3}$/,
  DEVICES_HOME_PAGE_PATH: '/portal/devices/summary',
  END_USER_LOGIN_ADMIN_MSG:
    'This is Admin Portal. Please login with End User Portal',
  APP_VERSION: 'Preliminary 0.4.2',
  HIGHLIGHT_MENU: ['users.groups', 'devices.groups', 'trust.list'],
  LOADER_MESSAGE_PLEASE_WAIT: 'Please wait',
  GATE_END_TIME_HOURS: 12,
  MIN_PORT_RANGE: 15000,
  MAX_PORT_RANGE: 15009,
  DEVICE_SITE_MISSING:
    'Site associated with device is missing. Please update device and associate Site.',
  DIALOG_CANVAS_WIDTH: 1440,
  DIALOG_CANVAS_HEIGHT: 1050,
  CANVAS_WIDTH_OFFSET: 64,
  CANVAS_HEIGHT_OFFSET: 48,
  CANVAS_HEIGHT_OFFSET_WITH_FILE: 48 + 60,
  CONNECTION_PROFILE_PROTOCOLS: ['SSH', 'RDP', 'HTTP/S'],
  CONNECTION_PROFILE_PROTOCOLS_GATEWAY: ['Config', 'SSH'],
  CONNECTION_PROFILE_PROTOCOLS_GATEWAY_CONFIG: ['Config'],
  CONNECTION_PROFILE_ACCESS_PROFILES: ['Supervisor', 'Guest'],
  CONNECTION_PROFILE_AUTH_METHODS: ['Password', 'Key'],
  HOSTNAME_TYPES: [HostnameType.IP, HostnameType.URL],
  DEFAULT_WEB_SOCKET_URL: 'ws://localhost:8082',
  DEFAULT_WEB_SOCKET_PORT: 8082,
  AUTH_PROFILE_INFO_MESSAGE:
    'Authentication profiles define how a user authenticates against a device.If Authentication profile is absent then user will have to enter credentials before connecting to device',
  WEBSOCKET_URL_INFO:
    'WebSocket URL: The WebSocket URL will be used as {websocket-protocol}://{site-name}-rd:8082.',
  INBOUND_PORT_INFO:
    'The minimum and maximum port range defines the available ports for the site.',
  INBOUND_INFO:
    'An available port within this range, along with the Inbound IP address, will be used to open gate while establishing a connection with a device.',
  GAUCD_INFO:
    'When adding, editing, or removing a site, the portal API will execute a script with the specified action, site name, Guacd IP, and Guacd Port.',
  RULE_USER_GROUP_INFO:
    'Choose the user group to which the trust rule will apply. If the logged-in user is a member of this group, they will have access to the devices in the selected device group.',
  RULE_DEVICE_GROUP_INFO:
    'Choose the device group to which the trust rule will apply. The rule will be applicable to all devices within the chosen group.',
  ACCESS_WINDOW_DISPLAY_INFO_MESSAGE:
    'Access window is displayed based on timezone display settings. "Device Local Time" selection will be ignored from timezone display settings and access window will be displayed in "User browser time".',
  DELETE_CONNECTION_WARNING:
    'Please delete some connections to match the connection limit.',
  KEEP_ALIVE_INTERVAL: 10,
  DEVICE_DISCONNECTED_MESSAGE: 'Device is disconnected...',
  DASHBOARD_MENU: 'home',
  RULES_PAGE_PATH: '/portal/trust/list',
};
