import React, { useCallback, useRef } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { constants } from 'common/constants';
import TcTextField from 'common/components/TcTextField';

type NotificationFormValues = {
  notifications: { email: string }[];
};
const MAX_EMAIL_FIELDS = 10;

const NotificationsSettings: React.FC<{ onCancel?: () => void }> = ({
  onCancel,
}) => {
  const dispatch = useDispatch();
  const methods = useForm<NotificationFormValues>({
    defaultValues: { notifications: [{ email: '' }] },
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    control,
    reset,
    // watch,
    // formState: { isValid, isDirty },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'notifications',
  });

  const handleSaveClick = handleSubmit(async (data: NotificationFormValues) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const response = await apiClient.post(
        `/system-settings/notification-emails`,
        {
          notificationEmails: data.notifications
            .map((item) => item.email)
            .filter(Boolean),
        },
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: response.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
      onCancel?.();
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  });

  const isRunEffect = useRef(true);
  const getNotificationSettings = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const emailsResponse = await apiClient.get(
        `/system-settings/notification-emails`,
      );
      const emails = emailsResponse.data.data || [''];
      reset({ notifications: emails.map((email: string) => ({ email })) });
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch, reset]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getNotificationSettings();
      isRunEffect.current = false;
    }
  }, [getNotificationSettings, isRunEffect]);

  // const emailWatcher = watch('notifications');

  // const canAddEmail = (index: number) => {
  //   const emailRegex = constants.EMAIL_REGEX;
  //   return emailRegex.test(emailWatcher[index]?.email || '');
  // };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="body1">
          <strong>Notifications</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
          The following people will be notified by email of any changes
          including: 1) adding/updating a Superadmin or Admin and 2) changing
          the Notifications recipients.
          <strong> NOTE:</strong> Mailing must be set up for this function to
          work.
        </Typography>
        <FormProvider {...methods}>
          <form noValidate>
            <Grid container spacing={2} paddingTop={1}>
              {fields.map((field, index) => (
                <Grid item xs={12} sm={6} key={field.id}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={9}>
                      <TcTextField
                        name={`notifications.${index}.email`}
                        label={`Notified Person ${index + 1}`}
                        disabled
                        rules={{
                          pattern: {
                            value: constants.EMAIL_REGEX,
                            message: 'Invalid Email Address',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {fields.length < MAX_EMAIL_FIELDS &&
                        index === fields.length - 1 && (
                          <IconButton
                            color="primary"
                            onClick={() => append({ email: '' })}
                            disabled
                            // disabled={!canAddEmail(index)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        )}
                      {index > 0 && (
                        <IconButton
                          color="secondary"
                          data-testid={`RemoveIcon-${index}`}
                          disabled
                          onClick={() => remove(index)}>
                          <RemoveCircleIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Grid item xs={2}>
                  <Button
                    size="small"
                    type="submit"
                    onClick={handleSaveClick}
                    fullWidth
                    disabled
                    variant="contained"
                    color="info"
                    id="save"
                    // disabled={!isValid || !isDirty}
                  >
                    {'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default NotificationsSettings;
