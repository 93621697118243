import React from 'react';
import Guacamole from 'guacamole-common-js';

import { constants } from 'common/constants';
import { getUser } from 'common/helpers/utils';

interface UseSessionManagementProps {
  accessSessionId: string | null;
  disconnectGuacamoleClient: () => void;
  guacamoleClientState: number;
  deviceId: string;
}

export const useSessionManagement = ({
  accessSessionId,
  disconnectGuacamoleClient,
  guacamoleClientState,
  deviceId,
}: UseSessionManagementProps) => {
  React.useEffect(() => {
    const user = getUser();
    const baseURL =
      process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3001/api/v1/';
    const beanConURL = `${baseURL}devices/${deviceId}/closeSession/${accessSessionId}/${user.userId}/${user.loginSessionId}`;
    const handleUnload = async () => {
      try {
        // If the client is connected, disconnect and wait for confirmation
        if (
          guacamoleClientState === Guacamole.Client.State.CONNECTED &&
          accessSessionId
        ) {
          if (navigator.sendBeacon) {
            navigator.sendBeacon(beanConURL);
          } else {
            const payload = JSON.stringify({ accessSessionId });
            const accessToken =
              sessionStorage.getItem('access_token') ||
              localStorage.getItem('access_token');

            const requestHeaders = {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            };
            const apiUrl = `${baseURL}devices/${deviceId}/closeConnection`;
            fetch(apiUrl, {
              method: 'POST',
              headers: requestHeaders,
              body: payload,
              keepalive: true,
            }).catch((err) =>
              console.error('Failed to send disconnect request', err),
            );
          }
        }
      } catch (error) {
        console.error('Error while handling unload process:', error);
      }
    };
    window.addEventListener('pagehide', handleUnload);
    return () => {
      window.removeEventListener('pagehide', handleUnload);
    };
  }, [accessSessionId, deviceId, guacamoleClientState]);

  React.useEffect(() => {
    if (!accessSessionId) return;
    const worker = new Worker(`${process.env.PUBLIC_URL}/keepAliveWorker.js`);
    const accessToken =
      sessionStorage.getItem('access_token') ||
      localStorage.getItem('access_token');
    const baseURL =
      process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3001/api/v1/';
    if (worker && guacamoleClientState === Guacamole.Client.State.CONNECTED) {
      // Start the worker and pass the API endpoint
      worker.postMessage({
        type: 'start',
        url: `${baseURL}devices/accessSessions/${accessSessionId}`,
        token: accessToken,
        interval: process.env.REACT_APP_KEEP_ALIVE_INTERVAL
          ? +process.env.REACT_APP_KEEP_ALIVE_INTERVAL * 1000
          : constants.KEEP_ALIVE_INTERVAL * 1000,
      });
      worker.onmessage = (event) => {
        if (event.data.type === 'error') {
          disconnectGuacamoleClient();
        }
      };
    }

    return () => {
      // Stop the worker on component unmount
      if (worker) {
        worker.postMessage({ type: 'stop' });
        worker.terminate();
      }
    };
  }, [accessSessionId, disconnectGuacamoleClient, guacamoleClientState]);
};
