import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Guacamole from 'guacamole-common-js';
import {
  DeviceConnectionProfileForm,
  DeviceDetails,
  UsernameAndPasswordForm,
} from 'pages/devices/types';
import apiClient from 'common/apiClientAxios';
import { DeviceAccess } from 'common/types';
import { useDispatch } from 'react-redux';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { deviceAccessErrors } from 'pages/devices/device-access-errors';
import RDPLoginForm from './RDPLoginForm';
import { constants } from 'common/constants';
import {
  getGateEndTimeAndCountDownEndTime,
  getLoggedInUser,
  isAdminUser,
  isEndUser,
} from 'common/helpers/utils';
import { AuthenticationType, Protocol } from 'common/enums';
import CountdownDialog from 'common/components/CountDownDialog';
import AdminAccessCountdown from './AdminAccessCountDown';
import { useSessionManagement } from './useSessionManagement';
import useClipboardSync from './useClipboardSync';
// import FileTransferComponent from './FileTransferComponent';
interface RemoteAccessHTTPProps {
  open: boolean;
  onClose: () => void;
  device: DeviceDetails;
  isHideDialog?: boolean;
}
const RemoteAccessHTTP: React.FC<RemoteAccessHTTPProps> = ({
  open,
  onClose,
  device,
  isHideDialog = false,
}) => {
  const [guacamoleClient, setGuacamoleClient] = useState<Guacamole.Client>();
  const [guacamoleClientState, setGuacamoleClientState] = useState(-1);
  const [guacamoleTunnelState, setGuacamoleTunnelState] = useState(-1);
  const [showUsernamePasswordForm, setShowUsernamePasswordForm] =
    useState(false);
  const [showReconnectForm, setShowReconnectForm] = useState(false);
  const [hasAuthProfile] = useState(
    Boolean(
      device.accessProfile?.authType
        ? device.accessProfile?.authType !== AuthenticationType.MANUAL
        : device.accessProfile?.username && device.accessProfile?.password,
    ),
  );
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const accessEndTime = React.useMemo(
    () =>
      getGateEndTimeAndCountDownEndTime(
        device.accessProfile?.accessEndTime ?? 0,
      ),
    [device.accessProfile?.accessEndTime],
  );
  const [countDownOpen, setCountDownOpen] = useState<boolean>(false);
  const [accessSessionId, setAccessSessionId] = useState(null);
  // const [fileSystemObject, setFileSystemObject] =
  //   useState<Guacamole.Object | null>(null);
  const saveLogEvent = useCallback(
    async (information: string) => {
      const user = getLoggedInUser();
      await apiClient.post(`logEvent`, {
        siteId: device.siteId,
        deviceId: device.deviceId,
        deviceName: device.name,
        siteName: device.siteName,
        sessionIp: device.accessProfile?.deviceIP,
        severity: 'Info',
        logEventType: 'RDP Connection',
        information,
        email: user.email,
        username: user.username,
        sessionId: user.loginSessionId,
      });
    },
    [
      device.accessProfile?.deviceIP,
      device.deviceId,
      device.name,
      device.siteId,
      device.siteName,
    ],
  );
  const { enableCopyFromRemoteClipboard } = useClipboardSync(saveLogEvent);

  const onSubmitCredentials = async (data: UsernameAndPasswordForm) => {
    setErrorMessage('');
    setShowUsernamePasswordForm(false);
    const accessProfile = {
      ...device.accessProfile,
      name: device.accessProfile?.name ?? '',
      username: data.username,
      password: data.password,
    } as DeviceConnectionProfileForm;
    await getToken({
      connectionProfile: accessProfile,
      type: Protocol.HTTP_S,
      endTime: accessEndTime.endTime,
      siteId: device.siteId,
      siteName: device.siteName,
    });
  };

  const closeConnection = useCallback(
    async (
      gateId: number,
      inboundPort: number,
      session: { xrdpId?: string; sessionId?: number },
      inAccessSessionId?: string,
      errorMessage?: string,
    ) => {
      try {
        console.log(
          `Closing connection with Gate id:${gateId}, inboundPort: ${inboundPort}, session id: ${session.sessionId}`,
        );
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          }),
        );
        await apiClient.post(`devices/${device.deviceId}/closeConnection`, {
          siteId: device.siteId,
          gateId: gateId,
          inboundPort: inboundPort,
          type: Protocol.HTTP_S,
          sessionId: session?.sessionId,
          xrdpId: session?.xrdpId,
          accessSessionId: inAccessSessionId,
          errorMessage,
        });
        setAccessSessionId(null);
        console.log(
          `Connection closed successfully with Gate id:${gateId}, session id: ${session.sessionId}`,
        );
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: 'error',
          }),
        );
      }
    },
    [device.deviceId, device.siteId, dispatch],
  );

  const connectToDevice = useCallback(
    (
      token: string,
      webSocketBaseURL: string,
      gateId: number,
      inboundPort: number,
      session: { xrdpId?: string; sessionId?: number },
      inAccessSessionId?: string,
    ) => {
      window.setTimeout(() => {
        const display = document.getElementById('VNCdisplay');
        const container = document.getElementById('container');
        const width = container?.clientWidth
          ? container?.clientWidth - constants.CANVAS_WIDTH_OFFSET
          : constants.DIALOG_CANVAS_WIDTH;
        const height = container?.clientHeight
          ? container?.clientHeight - constants.CANVAS_HEIGHT_OFFSET
          : constants.DIALOG_CANVAS_HEIGHT;
        let clientConnectedState = -1;
        let connectionError: string;
        if (display && token) {
          const guacTunnel = new Guacamole.WebSocketTunnel(
            `${webSocketBaseURL}/?token=${token}&width=${width}&height=${height}&`,
          );
          const guac = new Guacamole.Client(guacTunnel);
          setGuacamoleClient(guac);
          const displayElement = guac.getDisplay().getElement();

          // Add client to display div
          const canvas = displayElement.querySelector('canvas');
          if (canvas) {
            (canvas as HTMLCanvasElement).style.zIndex = '2';
          }
          display.replaceChildren(displayElement);
          // guac.onfilesystem = function (object: Guacamole.Object) {
          //   setFileSystemObject(object);
          // };
          // Mouse
          const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
          mouse.onEach(['mousedown', 'mousemove', 'mouseup'], () => {
            guac.sendMouseState(mouse.currentState);
          });
          // Keyboard
          const sink = new Guacamole.InputSink();
          const sinkElement = sink.getElement();
          sinkElement.tabIndex = -1;
          display.appendChild(sinkElement);
          const keyboard = new Guacamole.Keyboard(display);
          keyboard.listenTo(sinkElement);
          keyboard.onkeydown = function (keysym) {
            guac.sendKeyEvent(1, keysym);
          };
          keyboard.onkeyup = function (keysym) {
            guac.sendKeyEvent(0, keysym);
          };
          let isConnectionClosed = false;
          guac.onstatechange = (state: Guacamole.Client.State) => {
            console.log('Guacamole Client State =', state);
            setGuacamoleClientState(state);
            if (state === Guacamole.Client.State.DISCONNECTED) {
              console.log('Guacamole Client Disconnected');
              handleDisconnect();
            }
            if (state === Guacamole.Client.State.CONNECTED) {
              clientConnectedState = Guacamole.Client.State.CONNECTED;
              setCountDownOpen(true);
              enableCopyFromRemoteClipboard(guac);
            }
          };
          guacTunnel.onstatechange = (state: Guacamole.Tunnel.State) => {
            console.log('Guacamole Tunnel State =', state);
            setGuacamoleTunnelState(state);
            if (state === Guacamole.Tunnel.State.CLOSED) {
              console.log('Guacamole Tunnel Disconnected');
              //check if no error message is set and connection is established before disconnect then set error message
              if (
                !connectionError &&
                clientConnectedState !== Guacamole.Client.State.CONNECTED
              ) {
                connectionError =
                  'Something went wrong. Guacamole disconnected.';
                setErrorMessage(connectionError);
              }
              handleDisconnect();
            }
          };
          const handleDisconnect = () => {
            hasAuthProfile
              ? setShowReconnectForm(true)
              : setShowUsernamePasswordForm(true);
            if (display.hasChildNodes()) display.replaceChildren();
            clientConnectedState === Guacamole.Client.State.CONNECTED &&
              guac.disconnect();
            //close connection
            if (!isConnectionClosed) {
              isConnectionClosed = true;
              closeConnection(
                gateId,
                inboundPort,
                session,
                inAccessSessionId,
                connectionError,
              );
            }
          };
          const handleError = (error: Guacamole.Status) => {
            console.error(
              deviceAccessErrors[error.code] ?? 'Something went wrong...',
            );
            connectionError =
              deviceAccessErrors[error.code] ??
              error.message ??
              'Something went wrong...';
            setErrorMessage(connectionError);
          };
          // Error handler
          guac.onerror = function (error) {
            handleError(error);
          };
          guacTunnel.onerror = function (error) {
            handleError(error);
          };
          // Connect
          guac.connect(' ');
        }
      }, 1000);
    },
    [closeConnection, enableCopyFromRemoteClipboard, hasAuthProfile],
  );

  const getToken = useCallback(
    async (connectionSettings: DeviceAccess) => {
      console.log(
        `Getting token for device id:${device.deviceId} with site id: ${connectionSettings.siteId} `,
      );
      try {
        setErrorMessage('');
        dispatch(
          setLoader({
            loaderMessage: 'Please wait connecting...',
            openLoader: true,
          }),
        );
        const user = getLoggedInUser();
        console.log('getToken api called');
        const api = `devices/${device.deviceId}/getToken`;
        const tokenResponse = await apiClient.post(api, {
          ...connectionSettings,
          userId: user.userId,
          loginSessionId: user.loginSessionId,
        });
        const tokenResponseData = tokenResponse.data.data;
        const token = tokenResponseData.token;
        setAccessSessionId(tokenResponseData.accessSessionId);
        dispatch(
          setLoader({
            loaderMessage: 'Please wait connecting...',
            openLoader: false,
          }),
        );
        //connect to guacamole server
        const webSocketBaseURL =
          process.env.REACT_APP_USE_LOCAL_WEB_SOCKET_URL?.toLowerCase() ===
          'yes'
            ? process.env.REACT_APP_WEB_SOCKET_URL ??
              constants.DEFAULT_WEB_SOCKET_URL
            : `${process.env.REACT_APP_WEB_SOCKET_PROTOCOL ?? 'ws'}://${
                connectionSettings.siteName
              }-rc:${constants.DEFAULT_WEB_SOCKET_PORT}`;
        connectToDevice(
          token,
          webSocketBaseURL,
          tokenResponseData.gateId,
          tokenResponseData.inboundPort,
          tokenResponseData.sessionResponse,
          tokenResponseData.accessSessionId,
        );
        return token;
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: 'Please wait connecting...',
            openLoader: false,
          }),
        );
        // const errorData =
        //   error.response?.data?.meta?.message || String(error.message);
        const errorData =
          error.response?.data?.error?.message ||
          error.response?.data?.meta?.message ||
          String(error.message);
        setErrorMessage(errorData);
        setShowReconnectForm(true);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: 'error',
          }),
        );
        //if dialog then close
        if (!isHideDialog) {
          onClose();
        }
      }
    },
    [connectToDevice, device.deviceId, dispatch, isHideDialog, onClose],
  );

  const disconnectGuacamoleClient = useCallback(() => {
    setAccessSessionId(null);
    setErrorMessage(constants.DEVICE_DISCONNECTED_MESSAGE);
    guacamoleClientState === Guacamole.Client.State.CONNECTED &&
      guacamoleClient?.disconnect();
  }, [guacamoleClient, guacamoleClientState]);

  const onDisconnect = () => {
    disconnectGuacamoleClient();
    window.setTimeout(() => onClose(), 100);
  };

  const reConnectWithAuthProfile = async () => {
    if (device.accessProfile) {
      setShowReconnectForm(false);
      await getToken({
        connectionProfile: device.accessProfile,
        type: Protocol.HTTP_S,
        endTime: accessEndTime.endTime,
        siteId: device.siteId,
        siteName: device.siteName,
      });
    }
  };

  const getTokenWithAuthProfile = useCallback(
    async (accessProfile: DeviceConnectionProfileForm) => {
      await getToken({
        connectionProfile: accessProfile,
        type: Protocol.HTTP_S,
        endTime: accessEndTime.endTime,
        siteId: device.siteId,
        siteName: device.siteName,
      });
    },
    [accessEndTime, device.siteId, getToken, device.siteName],
  );
  const isRunEffect = useRef(true);
  React.useEffect(() => {
    if (isRunEffect.current) {
      isRunEffect.current = false;
      if (device.accessProfile && hasAuthProfile) {
        getTokenWithAuthProfile(device.accessProfile);
      } else {
        setShowUsernamePasswordForm(true);
      }
    }
  }, [device.accessProfile, getTokenWithAuthProfile, hasAuthProfile]);

  const handleCloseCountDownPopup = () => {
    setCountDownOpen(false);
  };

  const handleTimeUp = () => {
    setCountDownOpen(true);
  };

  useSessionManagement({
    accessSessionId,
    disconnectGuacamoleClient,
    guacamoleClientState,
    deviceId: device.deviceId,
  });

  return isHideDialog ? (
    <Card sx={{ overflow: 'auto', minHeight: '100vh' }}>
      <CardHeader
        sx={{ paddingTop: 1, paddingBottom: 0 }}
        titleTypographyProps={{ textAlign: 'center' }}
        title={
          <>
            <Typography component="span" sx={{ fontSize: 16 }}>
              {`Connect to device: '${device.name}' URL: ${
                device.accessProfile?.deviceIP ??
                device.accessProfile?.urls?.[0] ??
                ''
              } using ${device.accessProfile?.name} account`}
            </Typography>
            <br />
            {isAdminUser() ? (
              <AdminAccessCountdown deviceTimezone={device.timezone} />
            ) : (
              <CountdownDialog
                open={false}
                countDownTime={accessEndTime.countDownTime}
                handleDisconnectOkayClick={onDisconnect}
                onClose={handleCloseCountDownPopup}
                deviceTimezone={device.timezone}
                onTimeUp={handleTimeUp}
                isShowOnlyCountdown={
                  guacamoleClientState === Guacamole.Client.State.CONNECTED
                }></CountdownDialog>
            )}
            {/* {fileSystemObject &&
              guacamoleClient &&
              guacamoleClientState === Guacamole.Client.State.CONNECTED &&
              guacamoleTunnelState === Guacamole.Tunnel.State.OPEN && (
                <FileTransferComponent
                  guacamoleClient={guacamoleClient}
                  protocol={'rdp'}
                  saveLogEvent={saveLogEvent}
                  fileSystemObject={fileSystemObject}></FileTransferComponent>
              )} */}
          </>
        }
        action={
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={onDisconnect}>
            {'Close'}
          </Button>
        }
      />
      <CardContent sx={{ minHeight: '90vh' }} id="container">
        <Grid container justifyContent="center" alignItems="center">
          {/* Loading State */}
          {(guacamoleTunnelState === Guacamole.Tunnel.State.CONNECTING ||
            guacamoleClientState === Guacamole.Client.State.CONNECTING ||
            guacamoleClientState === Guacamole.Client.State.WAITING) &&
            guacamoleTunnelState !== Guacamole.Tunnel.State.CLOSED && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center">
            {/* Error Message */}
            {(guacamoleClientState === Guacamole.Client.State.DISCONNECTED ||
              guacamoleTunnelState === Guacamole.Tunnel.State.CLOSED ||
              errorMessage) && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {errorMessage ?? 'Something went wrong...'}
                </Typography>
              </Grid>
            )}
            {/* Login Form */}
            {!hasAuthProfile && showUsernamePasswordForm && (
              <RDPLoginForm
                handleSubmitForm={onSubmitCredentials}
                onDisconnect={onDisconnect}></RDPLoginForm>
            )}
            {hasAuthProfile && showReconnectForm && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={12}
                spacing={2}>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => reConnectWithAuthProfile()}>
                    {'ReConnect'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={onDisconnect}
                    id="cancel">
                    {'Cancel'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <div id="VNCdisplay" tabIndex={-1} style={{ outline: 'none' }}></div>
        {isEndUser() && (
          <CountdownDialog
            open={countDownOpen}
            countDownTime={accessEndTime.countDownTime}
            onClose={handleCloseCountDownPopup}
            handleDisconnectOkayClick={onDisconnect}
            deviceTimezone={device.timezone}
            onTimeUp={handleTimeUp}></CountdownDialog>
        )}
      </CardContent>
    </Card>
  ) : (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        style: {
          minHeight: '95vh',
          minWidth: '80vw',
        },
      }}>
      <DialogTitle sx={{ m: 0, p: 1 }} textAlign={'center'}>
        <Typography component="span" sx={{ fontSize: 16 }}>
          {`Connect to device: '${device.name}'  ${
            device.accessProfile?.deviceIP
              ? `IP: ${device.accessProfile?.deviceIP}`
              : `URL: ${device.accessProfile?.urls?.[0]}`
          } 
          using ${device.accessProfile?.name} account`}
        </Typography>
        <br />
        {isAdminUser() ? (
          <AdminAccessCountdown deviceTimezone={device.timezone} />
        ) : (
          <CountdownDialog
            open={false}
            countDownTime={accessEndTime.countDownTime}
            handleDisconnectOkayClick={onDisconnect}
            onClose={handleCloseCountDownPopup}
            deviceTimezone={device.timezone}
            onTimeUp={handleTimeUp}
            isShowOnlyCountdown={
              guacamoleClientState === Guacamole.Client.State.CONNECTED
            }></CountdownDialog>
        )}
        {/* {fileSystemObject &&
          guacamoleClient &&
          guacamoleClientState === Guacamole.Client.State.CONNECTED &&
          guacamoleTunnelState === Guacamole.Tunnel.State.OPEN && (
            <FileTransferComponent
              guacamoleClient={guacamoleClient}
              protocol={'rdp'}
              saveLogEvent={saveLogEvent}
              fileSystemObject={fileSystemObject}></FileTransferComponent>
          )} */}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onDisconnect}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent id="container" sx={{ maxHeight: '85vh', pb: 0, pt: 1 }}>
        <Grid container justifyContent="center" alignItems="center">
          {/* Loading State */}
          {(guacamoleTunnelState === Guacamole.Tunnel.State.CONNECTING ||
            guacamoleClientState === Guacamole.Client.State.CONNECTING ||
            guacamoleClientState === Guacamole.Client.State.WAITING) &&
            guacamoleTunnelState !== Guacamole.Tunnel.State.CLOSED && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center">
            {/* Error Message */}
            {(guacamoleClientState === Guacamole.Client.State.DISCONNECTED ||
              guacamoleTunnelState === Guacamole.Tunnel.State.CLOSED ||
              errorMessage) && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {errorMessage ?? 'Something went wrong...'}
                </Typography>
              </Grid>
            )}
            {/* Login Form */}
            {!hasAuthProfile && showUsernamePasswordForm && (
              <RDPLoginForm
                handleSubmitForm={onSubmitCredentials}
                onDisconnect={onDisconnect}></RDPLoginForm>
            )}
            {hasAuthProfile && showReconnectForm && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={12}
                spacing={2}>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => reConnectWithAuthProfile()}>
                    {'ReConnect'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={onDisconnect}
                    id="cancel">
                    {'Cancel'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <div id="VNCdisplay" tabIndex={-1} style={{ outline: 'none' }}></div>
        {isEndUser() && (
          <CountdownDialog
            open={countDownOpen}
            countDownTime={accessEndTime.countDownTime}
            onClose={handleCloseCountDownPopup}
            handleDisconnectOkayClick={onDisconnect}
            deviceTimezone={device.timezone}
            onTimeUp={handleTimeUp}></CountdownDialog>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={onDisconnect}>
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoteAccessHTTP;
