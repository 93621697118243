import React, { useCallback, useMemo, useRef, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch } from 'react-redux';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { User } from 'pages/users/types';
import { constants } from 'common/constants';
import DeviceAccessTab from './DeviceAccessTab';
import AccessWindowDisplaySettings from './AccessWindowDisplaySettings';
import { setUserData } from 'redux/UserStateSlice';
import Preferences from './Preferences';
import ProfileTab from './ProfileTab';
import ResetPasswordTab from './ResetPasswordTab';
import { UserStatus } from 'common/enums';
import { getUser } from 'common/helpers/utils';
import NotificationsSettings from './NotificationsSettings';

const AccountSettingsPage: React.FC<{ onCancel?: () => void }> = ({
  onCancel,
}) => {
  const sessionUser: User = useMemo(
    () =>
      getUser() || {
        roleName: '',
        userId: '',
        name: '',
        email: '',
        firstName: '',
        lastName: '',
        username: '',
        roleId: '',
        fromTime: '',
        ndnIdentity: '',
        recurrence: '',
        toTime: '',
        groups: [],
        status: UserStatus.ACTIVE,
      },
    [],
  );
  const [tabValue, setTabValue] = useState('1');
  const [userData, setUserDetails] = useState<User>(sessionUser);
  const dispatch = useDispatch();
  const isRunEffect = useRef(true);
  //Fetch user details from backend
  const getUserDetails = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const userId = sessionUser ? sessionUser.userId : '';
      const api = `users/${userId}`;
      const userDetailsResponse = await apiClient.get(api);
      const userDetails = userDetailsResponse.data.data as User;
      setUserDetails({ ...sessionUser, ...userDetails });
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch, sessionUser]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getUserDetails();
      isRunEffect.current = false;
    }
  }, [getUserDetails, isRunEffect]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const onCancelResetPassword = () => {
    setTabValue('1');
  };

  const onUpdatePassword = () => {
    setTabValue('1');
  };

  const onCancelAccessType = () => {
    setTabValue('1');
  };
  const onCancelAccessWindowDisplay = () => {
    setTabValue('1');
  };

  const onUpdateAccessType = (deviceAccessPreference: string) => {
    setUserDetails((prevState) => ({
      ...prevState,
      deviceAccessPreference,
    }));
    sessionStorage.getItem('user')
      ? sessionStorage.setItem(
          'user',
          JSON.stringify({ ...sessionUser, deviceAccessPreference }),
        )
      : localStorage.setItem(
          'user',
          JSON.stringify({ ...sessionUser, deviceAccessPreference }),
        );
    dispatch(
      setUserData({
        ...sessionUser,
        ...userData,
        isLoggedIn: true,
        deviceAccessPreference,
      }),
    );
    setTabValue('1');
  };

  const onUpdateAccessWindowDisplay = (accessWindowDisplay: string) => {
    setUserDetails((prevState) => ({
      ...prevState,
      accessWindowDisplay,
    }));
    sessionStorage.getItem('user')
      ? sessionStorage.setItem(
          'user',
          JSON.stringify({ ...sessionUser, accessWindowDisplay }),
        )
      : localStorage.setItem(
          'user',
          JSON.stringify({ ...sessionUser, accessWindowDisplay }),
        );
    dispatch(
      setUserData({
        ...sessionUser,
        ...userData,
        isLoggedIn: true,
        accessWindowDisplay,
      }),
    );
    setTabValue('1');
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="secondary">
        <Tab label="Profile" value={'1'} />
        <Tab label="Security" value={'2'} />
        <Tab label="Connection Window Options" value={'3'} />
        <Tab label="Time Zone Display" value={'4'} />
        <Tab label="Preferences" value={'5'} />
        {constants.SUPER_ADMIN_ROLE_PATTERN.test(userData.roleName) && (
          <Tab label="Notifications" value={'6'} />
        )}
      </TabList>
      <TabPanel value="1">
        <ProfileTab user={userData} getUpdatedUser={getUserDetails} />
      </TabPanel>
      <TabPanel value="2">
        <ResetPasswordTab
          user={userData}
          onCancel={onCancelResetPassword}
          onUpdatePassword={onUpdatePassword}
        />
      </TabPanel>
      <TabPanel value="3">
        <DeviceAccessTab
          user={userData}
          onCancel={onCancelAccessType}
          onUpdateAccessType={onUpdateAccessType}
        />
      </TabPanel>
      <TabPanel value="4">
        <AccessWindowDisplaySettings
          user={userData}
          onCancel={onCancelAccessWindowDisplay}
          onUpdateAccessWindowDisplay={onUpdateAccessWindowDisplay}
        />
      </TabPanel>
      <TabPanel value="5">
        <Preferences user={sessionUser} />
      </TabPanel>
      {constants.SUPER_ADMIN_ROLE_PATTERN.test(userData.roleName) && (
        <TabPanel value="6">
          <NotificationsSettings onCancel={onCancel} />
        </TabPanel>
      )}
    </TabContext>
  );
};

export default AccountSettingsPage;
