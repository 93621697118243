import * as React from 'react';
import { useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { constants } from 'common/constants';
import { SiteOwner } from 'pages/settings/types';
import { hasPermission } from 'common/helpers/utils';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import { CustomToolbarWithOnlySearch } from 'common/components/CustomToolbarWithOnlySearch';

type SiteOwnersTableProps = {
  data: SiteOwner[];
  handleEditSiteOwner: (owner: SiteOwner) => void;
  handleDeleteSiteOwner: (owner: SiteOwner) => void;
};

const SiteOwnersTable: React.FC<SiteOwnersTableProps> = (props) => {
  const [rows, setRows] = React.useState<SiteOwner[]>(props.data);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const editSiteOwner = React.useCallback(
    (gridRow: any) => () => {
      props.handleEditSiteOwner(gridRow.row);
    },
    [props],
  );

  const deleteSiteOwner = React.useCallback(
    (gridRow: any) => () => {
      props.handleDeleteSiteOwner(gridRow.row);
    },
    [props],
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        type: 'string',
        headerName: 'SiteOwner Name',
        flex: 1,
      },
      {
        field: 'description',
        type: 'string',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            disabled={!hasPermission('owners', 'update')}
            onClick={editSiteOwner(params)}
            showInMenu
            key="editSiteOwner"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            disabled={!hasPermission('owners', 'delete')}
            label="Delete"
            onClick={deleteSiteOwner(params)}
            showInMenu
            key="deleteSiteOwner"
          />,
        ],
      },
    ],
    [deleteSiteOwner, editSiteOwner],
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(row) => row.ownerId}
      pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
      initialState={{
        pagination: { paginationModel: constants.PAGINATION_MODEL },
      }}
      autoHeight
      slots={{
        toolbar: CustomToolbarWithOnlySearch,
        noRowsOverlay: () => (
          <NoRowsOverlay
            hasAccess={hasPermission('owners', 'read')}
            name="SiteOwners"
          />
        ),
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '600',
        },
      }}
    />
  );
};

export default SiteOwnersTable;
