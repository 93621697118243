import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { FC, useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { literal, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/Store';
import { loginADUser, loginUser } from 'redux/UserStateSlice';
import { AnyAction } from '@reduxjs/toolkit';

import { setSnackbarToast } from 'redux/UiStateSlice';
import FormInputText from '../../common/components/FormInputText';
import logo from '../../assets/images/cropped-logo_transparent-copy-smaller-e1531598038269.png';
import mslogo from '../../assets/images/Microsoft-Authenticator250x74.png';

import { constants } from 'common/constants';
import useAuth from './authService';
import { useMsal } from '@azure/msal-react';
import {
  CompareValues,
  IdentityAccessProvider,
  LoginFeature,
} from 'common/enums';
import { useErrorBoundary } from 'react-error-boundary';
import { getUser } from 'common/helpers/utils';
import { setThemeMode } from 'redux/ThemeSlice';

//Login Schema with Zod
const loginSchema = object({
  username: string().min(1, 'username is required'),
  password: string().min(1, 'Password is required'),
  persistUser: literal(true).optional(),
});

// Infer the Schema to get the TS Type
type ILogin = TypeOf<typeof loginSchema>;
const useStyles = makeStyles((theme: Theme) => ({
  logo_img: {
    paddingTop: 20,
  },
  logo_img_ms: {
    borderRadius: 4,
    padding: 6,
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.background.default,
  },
}));

// styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginWithAzureAD } = useAuth();
  const { showBoundary } = useErrorBoundary();
  const { inProgress } = useMsal();
  const { error, status } = useSelector((state: RootState) => state.userState);

  const { availableLogins, isInitializeSettings } = useSelector(
    (state: RootState) => state.loginSettingsState,
  );
  const classes = useStyles();

  // Default Values
  const defaultValues: ILogin = {
    username: '',
    password: '',
  };

  // The object returned from useForm Hook
  const methods = useForm<ILogin>({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<ILogin> = (values: ILogin) => {
    //check if portal is for admin or endUser
    const portalType = process.env.REACT_APP_PORTAL_TYPE;
    const username = values.username.trim();
    const password = values.password.trim();
    if (availableLogins.includes(LoginFeature.CUSTOM)) {
      dispatch(
        loginUser({ username, password, portalType }) as unknown as AnyAction,
      );
    } else if (availableLogins.includes(LoginFeature.LOCAL_AD)) {
      dispatch(
        loginADUser({ username, password, portalType }) as unknown as AnyAction,
      );
    }
  };
  const { logout } = useAuth();

  useEffect(() => {
    const isAuthenticated =
      !!localStorage.getItem('access_token') && !!localStorage.getItem('user');
    if (status === 'succeeded' || isAuthenticated) {
      const portalType = process.env.REACT_APP_PORTAL_TYPE?.toLowerCase();
      const portalRestrictSite =
        process.env.REACT_APP_PORTAL_RESTRICT_SITE?.toLowerCase();
      //check if portal is for admin or endUser
      const authUser = getUser();
      dispatch(setThemeMode(authUser.mode ?? 'light'));
      //If portal type is ENDUSER or logged in user is endUser and restricted sites then navigate to devices
      (constants.END_USER_ROLE_PATTERN.test(authUser.roleName) ||
        portalType === CompareValues.ENDUSER) &&
      portalRestrictSite === CompareValues.TRUE
        ? navigate(constants.DEVICES_HOME_PAGE_PATH)
        : constants.MANAGER_RULES_ROLE_PATTERN.test(authUser.roleName ?? '')
        ? navigate(constants.RULES_PAGE_PATH)
        : navigate(constants.HOME_PAGE_PATH);
    }
  }, [navigate, dispatch, logout, status]);

  useEffect(() => {
    error &&
      status === 'failed' &&
      dispatch(
        setSnackbarToast({
          message: error,
          open: true,
          severity: 'error',
        }),
      );
  }, [error, dispatch, status]);

  useEffect(() => {
    if (
      (!availableLogins || availableLogins.length === 0) &&
      isInitializeSettings
    ) {
      showBoundary({ message: 'Login settings are missing' });
    }
  }, [availableLogins, dispatch, isInitializeSettings, showBoundary]);

  return (
    <>
      {localStorage.getItem('login_with') === IdentityAccessProvider.AZURE &&
      inProgress === 'login' ? (
        <>
          <Backdrop open={true} className={classes.backdrop}>
            <CircularProgress color="inherit" />
            <Typography component="h5" variant="h5">
              Processing Azure login...
            </Typography>
          </Backdrop>
        </>
      ) : (
        <Container maxWidth={false} sx={{ height: '100vh' }}>
          {/* Custom login */}
          {(availableLogins.includes(LoginFeature.CUSTOM) ||
            availableLogins.includes(LoginFeature.LOCAL_AD)) && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}>
              <FormProvider {...methods}>
                <Grid item container justifyContent="center">
                  <Box
                    display="flex"
                    flexDirection="column"
                    component="form"
                    noValidate
                    autoComplete="off"
                    borderRadius={4}
                    sx={{
                      paddingX: 2,
                      mx: 'auto',
                      width: 400,
                      backgroundColor: 'background.paper',
                      color: 'text.primary',
                    }}
                    onSubmit={methods.handleSubmit(onSubmitHandler)}>
                    <img
                      src={logo}
                      alt="Operant Networks"
                      className={classes.logo_img}
                    />
                    <Typography
                      variant="h6"
                      component="h1"
                      sx={{
                        textAlign: 'center',
                        mb: '1.5rem',
                        mt: '1.5rem',
                      }}>
                      Log into your account
                    </Typography>
                    <FormInputText
                      label="Username"
                      type="text"
                      name="username"
                      required
                    />
                    <FormInputText
                      label="Password"
                      type="password"
                      name="password"
                      required
                    />
                    <LoadingButton
                      loading={false}
                      type="submit"
                      variant="contained"
                      color="info"
                      sx={{
                        py: '0.8rem',
                        mt: 2,
                        width: '80%',
                        marginInline: 'auto',
                        mb: 4,
                      }}>
                      Login
                    </LoadingButton>
                  </Box>
                </Grid>
              </FormProvider>
            </Grid>
          )}

          {/* Azure login */}
          {availableLogins.includes(LoginFeature.AZURE) && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}>
              <Box
                display="flex"
                flexDirection="column"
                component={'div'}
                borderRadius={4}
                sx={{
                  paddingX: 2,
                  mx: 'auto',
                  width: 400,
                  // backgroundColor: 'background.paper',
                  // color: 'primary.main',
                }}>
                <img
                  src={logo}
                  alt="Operant Networks"
                  className={classes.logo_img}
                />
                <Typography
                  variant="h6"
                  component="h1"
                  sx={{
                    textAlign: 'center',
                    mt: '1.5rem',
                  }}>
                  Log into your account
                </Typography>

                <Grid container justifyContent="center">
                  <Stack sx={{ textAlign: 'center' }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      borderRadius={4}
                      onClick={loginWithAzureAD}
                      sx={{
                        padding: 2,
                        mx: 'auto',
                        width: 400,
                        color: 'primary.main',
                        backgroundColor: 'background.paper',
                        cursor: 'pointer',
                      }}>
                      <Grid container>
                        <Typography
                          sx={{
                            fontSize: '0.7rem',
                            mt: 4,
                            mr: 2,
                            color: 'info.main',
                            textAlign: 'center',
                          }}>
                          <LinkItem to="#">Log in with</LinkItem>
                        </Typography>
                        <img
                          src={mslogo}
                          alt="Operant Networks"
                          className={classes.logo_img_ms}
                        />
                      </Grid>
                    </Box>
                  </Stack>
                </Grid>
              </Box>
            </Grid>
          )}
        </Container>
      )}
    </>
  );
};

export default LoginPage;
