import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSnackbarToast } from 'redux/UiStateSlice';
import { DeviceDetails } from '../types';
import RemoteAccessRDP from './RemoteAccessRDP';
import { useNavigate, useParams } from 'react-router-dom';
import { DeviceAccessMethod } from 'common/enums';
import RemoteAccessSSH from './RemoteAccessSSH';
import RemoteAccessHTTP from './RemoteAccessHTTP';

const RemoteAccessTab: React.FC = () => {
  const dispatch = useDispatch();
  const { accessMethod } = useParams();
  const accessProtocol = accessMethod?.split('_protocol')[0] || '';
  const navigate = useNavigate();
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails>(
    {} as DeviceDetails,
  );
  const getDeviceDetails = useCallback(async () => {
    let remoteAccess = sessionStorage.getItem('remote_access');
    if (!remoteAccess) {
      remoteAccess = localStorage.getItem('remote_access');
      if (remoteAccess) {
        sessionStorage.setItem('remote_access', remoteAccess);
        localStorage.removeItem('remote_access');
      } else {
        dispatch(
          setSnackbarToast({
            message: 'Please select proper device access',
            open: true,
            severity: 'error',
          }),
        );
        navigate('/portal/devices/summary');
        remoteAccess = null;
      }
    }
    let accessDevice: DeviceDetails;
    try {
      accessDevice = remoteAccess
        ? JSON.parse(remoteAccess)
        : ({} as DeviceDetails);
    } catch (error) {
      console.error('Error parsing remote access data:', error);
      accessDevice = {} as DeviceDetails;
    }
    if (!accessDevice.deviceId) {
      dispatch(
        setSnackbarToast({
          message: 'Please select proper device access',
          open: true,
          severity: 'error',
        }),
      );
      localStorage.setItem('remote_access', '');
      sessionStorage.setItem('remote_access', '');
      navigate('/portal/devices/summary');
    }
    setDeviceDetails(accessDevice);
  }, [dispatch, navigate]);

  useEffect(() => {
    getDeviceDetails();
  }, [getDeviceDetails]);

  const handleClose = () => {
    localStorage.setItem('remote_access', '');
    sessionStorage.setItem('remote_access', '');
    window.close();
  };

  return (
    <>
      {accessProtocol?.toLowerCase() === DeviceAccessMethod.RDP &&
        deviceDetails.deviceId && (
          <RemoteAccessRDP
            open={false}
            device={deviceDetails}
            isHideDialog={true}
            onClose={handleClose}></RemoteAccessRDP>
        )}
      {accessProtocol?.toLowerCase() === DeviceAccessMethod.SSH &&
        deviceDetails.deviceId && (
          <RemoteAccessSSH
            open={false}
            device={deviceDetails}
            isHideDialog={true}
            onClose={handleClose}></RemoteAccessSSH>
        )}
      {(accessProtocol?.toLowerCase() === DeviceAccessMethod.HTTP ||
        accessProtocol?.toLowerCase() === DeviceAccessMethod.HTTPS) &&
        deviceDetails.deviceId && (
          <RemoteAccessHTTP
            open={false}
            device={deviceDetails}
            isHideDialog={true}
            onClose={handleClose}></RemoteAccessHTTP>
        )}
    </>
  );
};

export default RemoteAccessTab;
