import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LoginSettingsType = {
  availableLogins: string[];
  isInitializeSettings: boolean;
};
const initialState: LoginSettingsType = {
  availableLogins: localStorage.getItem('availableLogins')
    ? JSON.parse(localStorage.getItem('availableLogins') ?? '[]')
    : [],
  isInitializeSettings: false,
};

const loginSettings = createSlice({
  name: 'loginSettings',
  initialState,
  reducers: {
    setLoginSettings: (state, action: PayloadAction<string[]>) => {
      state.availableLogins = action.payload;
      localStorage.setItem('availableLogins', JSON.stringify(action.payload));
      state.isInitializeSettings = true;
    },
  },
});

export const { setLoginSettings } = loginSettings.actions;

export default loginSettings.reducer;
