import { useCallback, useEffect, useState } from 'react';
import Guacamole from 'guacamole-common-js';

const useClipboardSync = (
  saveLogEvent: (information: string) => Promise<void>,
  intervalTime = 1000,
) => {
  const [guacamoleClient, setGuacamoleClient] =
    useState<Guacamole.Client | null>(null);
  const [clipboardContent, setClipboardContent] = useState<string>('');

  // Function to check and sync clipboard content to the remote server
  const checkClipboard = useCallback(async () => {
    try {
      if (navigator.clipboard && navigator.clipboard.readText) {
        const clipboardText = await navigator.clipboard.readText();
        if (
          clipboardText &&
          clipboardContent !== clipboardText &&
          guacamoleClient
        ) {
          const clipboardStream =
            guacamoleClient.createClipboardStream('text/plain');
          const writer = new Guacamole.StringWriter(clipboardStream);
          writer.sendText(clipboardText);
          writer.sendEnd();
          setClipboardContent(clipboardText);
          //Commenting save log event till we get confirmation on implementation
          // saveLogEvent(
          //   `Data copied from local sent to remote device clipboard.`,
          // );
        }
      } else {
        console.warn('Clipboard API is not supported in this browser.');
      }
    } catch (error) {
      console.error('Failed to read clipboard:', error);
    }
  }, [clipboardContent, guacamoleClient]);

  // Polling the clipboard every `intervalTime` ms when the document is focused
  useEffect(() => {
    const interval = setInterval(() => {
      if (document.hasFocus()) {
        checkClipboard();
      }
    }, intervalTime);
    return () => clearInterval(interval);
  }, [checkClipboard, intervalTime]);

  // Function to enable copying data from the remote clipboard to the user's clipboard
  const enableCopyFromRemoteClipboard = useCallback(
    (guacClient: Guacamole.Client) => {
      setGuacamoleClient(guacClient);

      guacClient.onclipboard = (stream, mimetype) => {
        let clipboardData = '';
        console.log(`Clipboard data received: ${mimetype}`);
        const reader = new Guacamole.StringReader(stream);

        reader.ontext = (text) => {
          clipboardData += text;
        };

        reader.onend = () => {
          navigator.clipboard.writeText(clipboardData).catch((err) => {
            console.error('Failed to write to clipboard:', err);
          });
          setClipboardContent(clipboardData);
          saveLogEvent(`Data copied from remote device to local clipboard.`);
        };
      };
    },
    [saveLogEvent],
  );

  return {
    enableCopyFromRemoteClipboard,
  };
};

export default useClipboardSync;
