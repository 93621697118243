import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { User } from 'pages/users/types';
import { useContentStyles } from 'common/styles/useContentStyles';
import { DeviceAccessTypeForm } from '../types';
import { constants } from 'common/constants';
import { RemoteAccessType } from 'common/enums';

type DeviceAccessTabProps = {
  user: User;
  onUpdateAccessType: (deviceAccessPreference: string) => void;
  onCancel: () => void;
};

const DeviceAccessTab: React.FC<DeviceAccessTabProps> = ({
  user,
  onUpdateAccessType,
  onCancel,
}) => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const methods = useForm<DeviceAccessTypeForm>({
    defaultValues: {
      deviceAccessPreference:
        user.deviceAccessPreference ?? RemoteAccessType.TAB,
    },
    mode: 'onBlur',
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleSaveClick = handleSubmit(async (data: DeviceAccessTypeForm) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const userResponse = await apiClient.put(
        `/users/${user.userId}/deviceAccessPreference`,
        {
          ...data,
        },
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: userResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
      onUpdateAccessType(data.deviceAccessPreference);
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
      onCancel();
    }
  });

  return (
    <Card variant="outlined">
      <CardHeader
        title="Connection Window Options"
        disableTypography
        className={classes.cardHeader}></CardHeader>
      <CardContent>
        <FormProvider {...methods}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Controller
                    name="deviceAccessPreference"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Grid container direction="row">
                          <Grid item>
                            <FormControlLabel
                              value={RemoteAccessType.DIALOG}
                              control={<Radio />}
                              label="Modal"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              value={RemoteAccessType.TAB}
                              control={<Radio />}
                              label="New Tab"
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Button
                  size="small"
                  type="submit"
                  onClick={handleSaveClick}
                  fullWidth
                  variant="contained"
                  color="info"
                  id="save"
                  disabled={!isValid}>
                  {'Save'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default DeviceAccessTab;
