import * as React from 'react';
import { useEffect } from 'react';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Card, Grid, IconButton } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import PersonIcon from '@mui/icons-material/Person';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  CreateUserGroup,
  User,
  UserFormMasterData,
  UserGroup,
  UserGroupNameForm,
  UsersFilterData,
} from './types';
import UsersFilter from './UsersFilter';
import {
  getFilteredUserRows,
  getUser,
  hasPermission,
  sortRows,
  stringAvatar,
} from 'common/helpers/utils';
import { FilterDisplayOption, UserGroupType, UserStatus } from 'common/enums';
import { useContentStyles } from 'common/styles/useContentStyles';
import { constants } from 'common/constants';
import CustomToolbar from 'common/components/CustomToolbar';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import apiClient from 'common/apiClientAxios';
import UserGroupTypeDialog from './groups/UserGroupTypeDialog';
import AddUserGroup from './groups/AddUserGroup';
import GroupType from './GroupType';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import UsersFilterChipDisplay from './UsersFilterChipDisplay';

type UsersTableProps = {
  data: User[];
  handleEditUser: (user: User) => void;
  handleDeleteUser: (user: User) => void;
  userFormMasterData: UserFormMasterData;
  showAddGroupForm: boolean;
  hideGroupForm: () => void;
  navigateToGroups: () => void;
  handleFavoriteUser: (user: User) => void;
};

const UsersTable: React.FC<UsersTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = React.useState<User[]>(props.data);
  const [selectedSortOption, setSelectedSortOption] = React.useState(
    FilterDisplayOption.ALL,
  );
  const [isFilterSubmitted, setFilterSubmitted] = React.useState(false);
  const location = useLocation();
  const { user } =
    (location.state as {
      user: User;
    }) || {};

  const getSortedRows = React.useCallback(
    (inputRows: User[], selectedValue?: FilterDisplayOption) => {
      const records = sortRows([...inputRows], selectedValue);
      setRows(records as User[]);
    },
    [],
  );

  const editUser = React.useCallback(
    (gridRow: any) => () => {
      props.handleEditUser(gridRow.row);
    },
    [props],
  );

  // const deleteUser = React.useCallback(
  //   (gridRow: any) => () => {
  //     props.handleDeleteUser(gridRow.row);
  //   },
  //   [props],
  // );

  const loginAsEndUser = React.useCallback(
    (gridRow: any) => () => {
      const url = `/portal/loginEndUser/${
        gridRow.row.username ?? gridRow.row.email
      }`;
      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        console.error(
          'Unable to open a new tab. Make sure pop-ups are allowed in the browser.',
        );
      }
    },
    [],
  );

  const handleFavoriteClick = React.useCallback(
    (user: User) => {
      props.handleFavoriteUser(user);
    },
    [props],
  );

  const hasEditDeletePermission = (rowUser: User) => {
    const loggedInUserRole = getUser()?.roleName;
    if (constants.SUPER_ADMIN_ROLE_PATTERN.test(loggedInUserRole)) {
      return false;
    } else if (constants.ADMIN_ROLE_PATTERN.test(loggedInUserRole)) {
      return constants.ADMIN_SUPER_ADMIN_ROLE_PATTERN.test(rowUser.roleName);
    }
    return true;
  };

  const getActions = React.useCallback(
    (params: GridRowParams<any>) => {
      const actions = [
        <GridActionsCellItem
          icon={<EditIcon color="info" />}
          label="Edit"
          disabled={
            !hasPermission('users.summary', 'update') ||
            hasEditDeletePermission(params.row)
          }
          onClick={editUser(params)}
          showInMenu
          key="editUser"
        />,
        // <GridActionsCellItem
        //   icon={<DeleteIcon color="info" />}
        //   label="Delete"
        //   disabled={
        //     !hasPermission('users.summary', 'delete') ||
        //     hasEditDeletePermission(params.row)
        //   }
        //   onClick={deleteUser(params)}
        //   showInMenu
        //   key="deleteUser"
        // />,
      ];
      if (
        params.row?.roleName?.toLowerCase() === constants.END_USER_ROLE_NAME
      ) {
        actions.push(
          <GridActionsCellItem
            icon={<PersonIcon color="info" />}
            label="Login As"
            onClick={loginAsEndUser(params)}
            showInMenu
            key="loginAsEndUser"
          />,
        );
      }
      return actions;
    },
    [editUser, loginAsEndUser],
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'avatar',
        headerName: '',
        disableExport: true,
        resizable: false,
        width: 100,
        renderCell: (params: any) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%">
            <IconButton
              disabled={props.showAddGroupForm}
              onClick={() => handleFavoriteClick(params.row)}
              sx={{ paddingLeft: 0 }}>
              {params.row.isFavorite ? (
                <StarIcon color="secondary" />
              ) : (
                <StarOutlineIcon />
              )}
            </IconButton>
            <Avatar
              {...stringAvatar(
                `${params.row.firstName ?? ''} ${params.row.lastName ?? ''}`,
              )}
            />
          </Box>
        ),
      },
      { field: 'firstName', type: 'string', headerName: 'First Name', flex: 1 },
      { field: 'lastName', type: 'string', headerName: 'Last Name', flex: 1 },
      { field: 'email', type: 'string', headerName: 'Email', flex: 1 },
      { field: 'roleName', type: 'string', headerName: 'Trust Role', flex: 1 },
      {
        field: 'groups',
        type: 'string',
        headerName: 'Group(s)',
        flex: 1,
        valueGetter: (value) => {
          const groups = (value || []) as UserGroup[];
          return groups.map((obj) => obj.name).join(', ');
        },
      },
      { field: 'country', type: 'string', headerName: 'Country', flex: 1 },
      {
        field: 'organization',
        type: 'string',
        headerName: 'Company/Organization',
        flex: 1,
        valueGetter: (value: any) => {
          return value?.name ?? '';
        },
      },
      {
        field: 'status',
        type: 'string',
        headerName: 'Status',
        width: 60,
        resizable: false,
        renderCell: (params) => {
          const status = params.row.status || '';
          if (status === UserStatus.ACTIVE) {
            return <CheckCircleOutlineIcon sx={{ color: green[500] }} />;
          } else if (status === UserStatus.INACTIVE) {
            return <BlockIcon sx={{ color: grey[500] }} />;
          } else {
            return status;
          }
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 40,
        resizable: false,
        getActions: getActions,
      },
    ],
    [getActions, handleFavoriteClick, props.showAddGroupForm],
  );

  const defaultFilterValues = React.useMemo(
    () => ({
      statuses: [],
      selectedCountries: [],
      city: '',
      organizations: [],
      roles: [],
      selectedStates: [],
      zipcode: '',
      groups: [],
    }),
    [],
  );
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filters, setFilters] =
    React.useState<UsersFilterData>(defaultFilterValues);

  const handleFilterClick = () => {
    setOpenFilter(true);
  };
  const handleApplyFilter = (filterValues: UsersFilterData) => {
    setFilters(filterValues);
    setFilterSubmitted(true);
    setOpenFilter(false);
  };

  const handleClearFilter = () => {
    setFilters(defaultFilterValues);
    setFilterSubmitted(false);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
    setFilterSubmitted(false);
    if (props.showAddGroupForm) {
      //reset row selection on cancel
      setRowSelectionModel([]);
      props.hideGroupForm();
    }
  };

  const onCancelGroup = () => {
    setGroupTypeSwitchValue(UserGroupType.STATIC);
    setFilters(defaultFilterValues);
    setOpenFilter(false);
    //reset row selection on cancel
    setRowSelectionModel([]);
    props.hideGroupForm();
  };
  const [userGroupName, setUserGroupName] = React.useState('');
  const dispatch = useDispatch();

  const onSaveGroup = (data: UserGroupNameForm) => {
    if (rowSelectionModel.length > 0) {
      setUserGroupName(data.name);
      //get type confirmation
      setOpenGroupTypeDialogue(true);
    } else {
      dispatch(
        setSnackbarToast({
          message: 'Please select the users',
          open: true,
          severity: 'error',
        }),
      );
    }
  };

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const [openGroupTypeDialogue, setOpenGroupTypeDialogue] =
    React.useState(false);

  const onConfirmType = async () => {
    const type = groupTypeSwitchValue;
    const userGroup: CreateUserGroup = {
      name: userGroupName,
      type,
      users: rowSelectionModel as string[],
      statuses: filters.statuses,
      roles: filters.roles,
      city: filters.city,
      countries: filters.selectedCountries,
      states: filters.selectedStates,
      organizations: filters.organizations,
    };
    //save group
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const groupResponse = await apiClient.post(`/users-groups`, userGroup);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: groupResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    setOpenGroupTypeDialogue(false);
    props.navigateToGroups();
  };

  useEffect(() => {
    if (props.showAddGroupForm) {
      setSelectedSortOption(FilterDisplayOption.ALL);
      setRows(props.data);
      setFilters(defaultFilterValues);
      setOpenFilter(true);
    }
  }, [defaultFilterValues, getSortedRows, props.data, props.showAddGroupForm]);

  const [groupTypeSwitchValue, setGroupTypeSwitchValue] = React.useState(
    UserGroupType.STATIC,
  );
  const onCancelGroupType = (type: UserGroupType) => {
    setGroupTypeSwitchValue(type);
  };

  const onConfirmSaveGroupType = (type: UserGroupType) => {
    if (type === UserGroupType.DYNAMIC) {
      const updatedRowsSelection = rows.map((row) => row.userId);
      setRowSelectionModel(updatedRowsSelection);
    }
    setGroupTypeSwitchValue(type);
  };

  const handleSortOptionChange = (selectedValue: FilterDisplayOption) => {
    setSelectedSortOption(selectedValue);
  };

  useEffect(() => {
    const filteredDevices = getFilteredUserRows(props.data, filters);
    getSortedRows(filteredDevices, selectedSortOption);
  }, [filters, getSortedRows, props.data, selectedSortOption]);

  return (
    <>
      {props.showAddGroupForm && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <AddUserGroup
              onCancelGroup={onCancelGroup}
              onSaveGroup={onSaveGroup}
              isShowCancel={!openFilter}></AddUserGroup>
          </Grid>
          <Grid item xs={3} alignContent={'center'}>
            <GroupType
              rows={rows}
              rowSelectionModel={rowSelectionModel as string[]}
              onCancel={onCancelGroupType}
              defaultGroupType={groupTypeSwitchValue}
              onConfirmSave={onConfirmSaveGroupType}
              filters={filters}
              isFilterSubmitted={isFilterSubmitted}></GroupType>
          </Grid>
        </Grid>
      )}
      {openFilter && (
        <Card elevation={0} className={classes.contentSection}>
          <UsersFilter
            onApplyFilter={handleApplyFilter}
            handleCancelFilter={handleCancelFilter}
            handleClearFilter={handleClearFilter}
            filters={filters}
            userFormMasterData={props.userFormMasterData}
            isShowCancel={!props.showAddGroupForm}
            isResetForm={props.showAddGroupForm}
          />
        </Card>
      )}
      <DataGrid
        disableVirtualization
        columns={columns}
        rows={rows}
        getRowId={(row) => row.userId}
        pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
        initialState={{
          pagination: { paginationModel: constants.PAGINATION_MODEL },
          columns: {
            columnVisibilityModel: {
              groups: false,
              country: false,
              status: false,
            },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [user ? user.email : undefined],
            },
          },
        }}
        slots={{
          toolbar: () => (
            <>
              <CustomToolbar
                handleFilterClick={handleFilterClick}
                isDisplaySortOptions={!props.showAddGroupForm}
                handleSortOptionChange={(selectedOption) =>
                  handleSortOptionChange(selectedOption)
                }
                sortOptionValue={selectedSortOption}
                tableName="Users"
              />
              {!openFilter && (
                <UsersFilterChipDisplay
                  userFormMasterData={props.userFormMasterData}
                  filters={filters}
                  handleUpdatedFilter={handleApplyFilter}
                  openFilter={() => setOpenFilter(true)}
                />
              )}
            </>
          ),
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission('users.summary', 'read')}
              name="Users"
            />
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoHeight
        sx={{
          marginTop: 1,
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
        checkboxSelection={props.showAddGroupForm}
        isRowSelectable={() => groupTypeSwitchValue === UserGroupType.STATIC}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
      />
      {openGroupTypeDialogue && (
        <UserGroupTypeDialog
          dialogContent={
            groupTypeSwitchValue === UserGroupType.STATIC
              ? constants.STATIC_GROUP_CONFIRM_MESSAGE
              : constants.DYNAMIC_GROUP_CONFIRM_MESSAGE
          }
          onConfirmType={onConfirmType}
          open={openGroupTypeDialogue}
          onCancel={() => {
            setOpenGroupTypeDialogue(false);
          }}></UserGroupTypeDialog>
      )}
    </>
  );
};
export default UsersTable;
