import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';

import TcAutocompleteMultiSelect from 'common/components/TcAutocompleteMultiSelect';
import { Site, SiteOperator, SiteOwner } from 'pages/sites/types';
import { SiteAccess } from 'common/enums';
import TcSelectDropdown from 'common/components/TcSelectDropdown';

type SiteSelectProps = {
  formMasterData: {
    siteOwners: SiteOwner[];
    siteOperators: SiteOperator[];
    sites: Site[];
  };
  formData: {
    selectedOwners: string[];
    selectedOperators: string[];
    selectedSites: string[];
    sitesAccess: SiteAccess;
  };
};

const SiteSelectForm: React.FC<SiteSelectProps> = (siteSelectProps) => {
  const { setValue, watch } = useFormContext();
  const { siteOwners, siteOperators, sites } = siteSelectProps.formMasterData;
  const sitePermissions = useMemo(
    () => [
      { name: 'All Sites', value: SiteAccess.ALL },
      { name: 'By Owner', value: SiteAccess.BY_OWNER },
      { name: 'By Operator', value: SiteAccess.BY_OPERATOR },
      { name: 'Select Sites', value: SiteAccess.BY_SITES },
    ],
    [],
  );
  const [selectedOwners, setSelectedOwners] = useState<string[]>(
    siteSelectProps.formData.selectedOwners,
  );
  const [selectedOperators, setSelectedOperators] = useState<string[]>(
    siteSelectProps.formData.selectedOperators,
  );
  const [selectedSites, setSelectedSites] = useState<string[]>(
    siteSelectProps.formData.selectedSites,
  );

  const onOwnerSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: SiteOwner[],
  ) => {
    const selectedIds = value.map((s) => s.ownerId);
    setValue('siteOwners', selectedIds, { shouldDirty: true });
    setSelectedOwners(selectedIds);
  };
  const onOperatorSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: SiteOperator[],
  ) => {
    const selectedIds = value.map((s) => s.operatorId);
    setValue('siteOperators', selectedIds, { shouldDirty: true });
    setSelectedOperators(selectedIds);
  };

  const onSiteSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: Site[],
  ) => {
    const selectedIds = value.map((s) => s.siteId);
    setValue('sites', selectedIds, { shouldDirty: true });
    setSelectedSites(selectedIds);
  };

  const watchedSitesAccess = watch('sitesAccess');

  return (
    <React.Fragment>
      <Grid item xs={6}>
        <TcSelectDropdown
          name="sitesAccess"
          label="Site Permissions"
          options={sitePermissions}
          optionKey="value"
          optionLabel="name"
        />
      </Grid>
      {watchedSitesAccess === SiteAccess.BY_OWNER && (
        <Grid item xs={6}>
          <TcAutocompleteMultiSelect
            name="siteOwners"
            value={
              selectedOwners.map((ownerId) =>
                siteOwners.find((o) => o.ownerId === ownerId),
              ) as SiteOwner[]
            }
            rules={{
              required: {
                value: watchedSitesAccess === SiteAccess.BY_OWNER,
                message: 'Please select site owner',
              },
            }}
            label="Owners"
            onValueSelect={onOwnerSelect}
            optionKey="ownerId"
            getOptionLabel={(owner) => owner.name}
            options={siteOwners}></TcAutocompleteMultiSelect>
        </Grid>
      )}

      {watchedSitesAccess === SiteAccess.BY_OPERATOR && (
        <Grid item xs={6}>
          <TcAutocompleteMultiSelect
            name="siteOperators"
            value={
              selectedOperators.map((operatorId) =>
                siteOperators.find((o) => o.operatorId === operatorId),
              ) as SiteOperator[]
            }
            rules={{
              required: {
                value: watchedSitesAccess === SiteAccess.BY_OPERATOR,
                message: 'Please select site operator',
              },
            }}
            label="Operators"
            onValueSelect={onOperatorSelect}
            optionKey="operatorId"
            getOptionLabel={(operator) => operator.name}
            options={siteOperators}></TcAutocompleteMultiSelect>
        </Grid>
      )}
      {watchedSitesAccess === SiteAccess.BY_SITES && (
        <Grid item xs={6}>
          <TcAutocompleteMultiSelect
            name="sites"
            value={
              selectedSites.map((siteId) =>
                sites.find((s) => s.siteId === siteId),
              ) as Site[]
            }
            rules={{
              required: {
                value: watchedSitesAccess === SiteAccess.BY_SITES,
                message: 'Please select sites',
              },
            }}
            label="Sites"
            onValueSelect={onSiteSelect}
            optionKey="siteId"
            getOptionLabel={(site) => site.name}
            options={sites}></TcAutocompleteMultiSelect>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default SiteSelectForm;
