import React, { useEffect, useState } from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { RootState } from 'redux/Store';
import { AnyAction } from '@reduxjs/toolkit';
import { ErrorBoundary } from 'react-error-boundary';

import MainLayout from './common/components/layout/MainLayout';
import LoginPage from './pages/login/LoginPage';
import './App.scss';
import customTheme from 'redux/CustomTheme';
import BackDropLoader from 'common/components/BackDropLoader';
import SnackbarToast from 'common/components/SnackbarToast';
import { fetchThemeSettings } from 'redux/ThemeSlice';
import ProtectedRoute from 'routes/ProtectedRoute';
import AzureADCallback from 'pages/login/AzureADCallback';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import {
  fetchLoginSettings,
  initializeMsalInstance,
} from 'pages/login/authConfig';
import { setSnackbarToast } from 'redux/UiStateSlice';
import useAppRoutes from 'routes/useAppRoutes';
import ErrorFallback from 'common/components/ErrorFallback';
import { AxiosInterceptor } from 'common/apiClientAxios';
import { setLoginSettings } from 'redux/LoginSettingsSlice';
import { LoginFeature } from 'common/enums';
import RemoteAccessTab from 'pages/devices/remoteAccess/RemoteAccessTab';
import EndUserLogin from 'pages/login/EndUserLogin';

function App() {
  const dispatch = useDispatch();
  const { generatedAppRoutes } = useAppRoutes();
  useEffect(() => {
    dispatch(fetchThemeSettings() as unknown as AnyAction);
  }, [dispatch]);

  const customThemeData = useSelector(
    (state: RootState) => state.themeState.themeData,
  );

  return (
    <div className="App">
      <>
        <CssBaseline />
        <ThemeProvider theme={customTheme(customThemeData)}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/portal"
              element={
                <ProtectedRoute>
                  <MainLayout />
                </ProtectedRoute>
              }>
              {generatedAppRoutes}
            </Route>
            <Route path="/redirect" element={<AzureADCallback />} />
            <Route
              path="/devices/remoteAccess/:deviceId/:accessMethod"
              element={
                <ProtectedRoute>
                  <RemoteAccessTab />
                </ProtectedRoute>
              }
            />
            <Route
              path="/portal/loginEndUser/:username"
              element={
                <ProtectedRoute>
                  <EndUserLogin />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<LoginPage />} />
          </Routes>
          <SnackbarToast></SnackbarToast>
          <BackDropLoader></BackDropLoader>
        </ThemeProvider>
      </>
    </div>
  );
}

function AppWrapper() {
  const [msalInstance, setMsalInstance] =
    useState<PublicClientApplication | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    //fetch login settings
    let loginMethod = localStorage.getItem('availableLogins')
      ? JSON.parse(localStorage.getItem('availableLogins') ?? '[]')
      : [];
    const isAuthenticated =
      !!localStorage.getItem('access_token') && !!localStorage.getItem('user');
    if (!loginMethod || loginMethod.length === 0 || !isAuthenticated) {
      fetchLoginSettings()
        .then((availableLogins) => {
          loginMethod = availableLogins;
          if (availableLogins.includes(LoginFeature.AZURE)) {
            initializeMsalInstance()
              .then((instance) => {
                setMsalInstance(instance);
              })
              .catch((error) => {
                dispatch(
                  setSnackbarToast({
                    message: `Failed to initialize MSAL instance: ${error}`,
                    open: true,
                    severity: 'error',
                  }),
                );
              });
          }
          dispatch(setLoginSettings(availableLogins));
        })
        .catch(() => {
          dispatch(
            setSnackbarToast({
              message: 'Failed to fetch login settings',
              open: true,
              severity: 'error',
            }),
          );
        });
    } else {
      if (loginMethod.includes(LoginFeature.AZURE)) {
        const storedMsalConfig = localStorage.getItem('msalConfig');
        if (storedMsalConfig) {
          const instance = new PublicClientApplication(
            JSON.parse(storedMsalConfig),
          );
          setMsalInstance(instance);
        }
      }
    }
  }, [dispatch]);

  return msalInstance ? (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AxiosInterceptor>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </AxiosInterceptor>
    </ErrorBoundary>
  ) : (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AxiosInterceptor>
        <App />
      </AxiosInterceptor>
    </ErrorBoundary>
  );
}

export default AppWrapper;
