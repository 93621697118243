import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Guacamole from 'guacamole-common-js';
import { DeviceDetails } from 'pages/devices/types';
import apiClient from 'common/apiClientAxios';
import { DeviceAccess } from 'common/types';
import { useDispatch } from 'react-redux';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { deviceAccessErrors } from 'pages/devices/device-access-errors';
import { constants } from 'common/constants';
import {
  getGateEndTimeAndCountDownEndTime,
  getLoggedInUser,
  isAdminUser,
  isEndUser,
} from 'common/helpers/utils';
import { Protocol } from 'common/enums';
import CountdownDialog from 'common/components/CountDownDialog';
import AdminAccessCountdown from './AdminAccessCountDown';
import useClipboardSync from './useClipboardSync';
import { useSessionManagement } from './useSessionManagement';
import FileTransferComponent from './FileTransferComponent';

interface RemoteAccessSSHProps {
  open: boolean;
  onClose: (gateId?: number) => void;
  device: DeviceDetails;
  isHideDialog?: boolean;
}

const RemoteAccessSSH: React.FC<RemoteAccessSSHProps> = ({
  open,
  onClose,
  device,
  isHideDialog = false,
}) => {
  const isRunEffect = useRef(true);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [guacamoleClient, setGuacamoleClient] = useState<Guacamole.Client>();
  const [fileSystemObject, setFileSystemObject] =
    useState<Guacamole.Object | null>(null);
  const [guacamoleClientState, setGuacamoleClientState] = useState(-1);
  const [guacamoleTunnelState, setGuacamoleTunnelState] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');
  const accessEndTime = React.useMemo(
    () =>
      getGateEndTimeAndCountDownEndTime(
        device.accessProfile?.accessEndTime ?? 0,
      ),
    [device.accessProfile?.accessEndTime],
  );
  const [countDownOpen, setCountDownOpen] = useState<boolean>(false);
  const [accessSessionId, setAccessSessionId] = useState(null);
  const dispatch = useDispatch();

  const saveLogEvent = useCallback(
    async (information: string) => {
      const user = getLoggedInUser();
      await apiClient.post(`logEvent`, {
        siteId: device.siteId,
        deviceId: device.deviceId,
        sessionIp: device.accessProfile?.deviceIP,
        deviceName: device.name,
        siteName: device.siteName,
        severity: 'Info',
        logEventType: 'SSH Connection',
        information,
        email: user.email,
        username: user.username,
        sessionId: user.loginSessionId,
      });
    },
    [
      device.accessProfile?.deviceIP,
      device.deviceId,
      device.name,
      device.siteId,
      device.siteName,
    ],
  );
  const { enableCopyFromRemoteClipboard } = useClipboardSync(saveLogEvent);

  const onReconnectSSH = async () => {
    if (device.accessProfile) {
      await getToken({
        connectionProfile: device.accessProfile,
        type: Protocol.SSH,
        endTime: accessEndTime.endTime,
        siteId: device.siteId,
        siteName: device.siteName,
      });
    }
  };

  const closeConnection = useCallback(
    async (
      gateId: number,
      inboundPort: number,
      inAccessSessionId?: string,
      errorMessage?: string,
    ) => {
      console.log(
        `Closing connection with Gate id:${gateId}, inboundPort: ${inboundPort}`,
      );
      try {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          }),
        );
        await apiClient.post(`devices/${device.deviceId}/closeConnection`, {
          siteId: device.siteId,
          gateId: gateId,
          inboundPort: inboundPort,
          type: Protocol.SSH,
          accessSessionId: inAccessSessionId,
          errorMessage,
        });
        setAccessSessionId(null);
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
        console.log(`Connection closed successfully with Gate id:${gateId}`);
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: 'error',
          }),
        );
      }
    },
    [device.deviceId, device.siteId, dispatch],
  );

  const disconnectGuacamoleClient = useCallback(() => {
    setAccessSessionId(null);
    setErrorMessage(constants.DEVICE_DISCONNECTED_MESSAGE);
    guacamoleClientState === Guacamole.Client.State.CONNECTED &&
      guacamoleClient?.disconnect();
  }, [guacamoleClient, guacamoleClientState]);

  const onDisconnect = () => {
    console.log(`Disconnecting device`);
    disconnectGuacamoleClient();
    window.setTimeout(() => onClose(), 100);
  };

  const disconnectFromCanvas = useCallback(
    async (inGateId: number, inInboundPort: number) => {
      await closeConnection(inGateId, inInboundPort);
      window.setTimeout(() => onClose(), 100);
    },
    [closeConnection, onClose],
  );

  const connectToDevice = useCallback(
    (
      token: string,
      inGateId: number,
      inInboundPort: number,
      webSocketBaseURL: string,
      inAccessSessionId?: string,
    ) => {
      window.setTimeout(() => {
        const display = document.getElementById('SSHdisplay');
        const container = document.getElementById('container');
        const width = container?.clientWidth
          ? container?.clientWidth - constants.CANVAS_WIDTH_OFFSET
          : constants.DIALOG_CANVAS_WIDTH;
        const height = container?.clientHeight
          ? container?.clientHeight - constants.CANVAS_HEIGHT_OFFSET
          : constants.DIALOG_CANVAS_HEIGHT;
        let clientConnectedState = -1;
        let connectionError: string;
        if (display && token) {
          const guacTunnel = new Guacamole.WebSocketTunnel(
            `${webSocketBaseURL}/?token=${token}&width=${width}&height=${height}&`,
          );
          const guac = new Guacamole.Client(guacTunnel);
          setGuacamoleClient(guac);
          display.replaceChildren(guac.getDisplay().getElement());
          guac.onfilesystem = function (object: Guacamole.Object) {
            setFileSystemObject(object);
          };
          // Mouse
          const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
          mouse.onEach(['mousedown', 'mousemove', 'mouseup'], () => {
            guac.sendMouseState(mouse.currentState);
          });
          // Keyboard
          const sink = new Guacamole.InputSink();
          const sinkElement = sink.getElement();
          sinkElement.tabIndex = -1;
          display.appendChild(sinkElement);
          const keyboard = new Guacamole.Keyboard(display);
          keyboard.listenTo(sinkElement);
          keyboard.onkeydown = function (keysym) {
            guac.sendKeyEvent(1, keysym);
          };
          keyboard.onkeyup = function (keysym) {
            guac.sendKeyEvent(0, keysym);
          };
          let isConnectionClosed = false;
          guac.onstatechange = async (state: Guacamole.Client.State) => {
            console.log('Guacamole Client State =', state);
            if (state === Guacamole.Client.State.CONNECTED) {
              clientConnectedState = Guacamole.Client.State.CONNECTED;
              setCountDownOpen(true);
              enableCopyFromRemoteClipboard(guac);
            }
            //if client connection closed and canvas is displayed then remove canvas and close dialog
            if (state === Guacamole.Client.State.DISCONNECTED) {
              console.log('Guacamole Client Disconnected');
              if (clientConnectedState === Guacamole.Client.State.CONNECTED) {
                window.setTimeout(async () => {
                  if (!isConnectionClosed) {
                    isConnectionClosed = true;
                    await disconnectFromCanvas(inGateId, inInboundPort);
                  }
                }, 200);
              }
            } else {
              setGuacamoleClientState(state);
            }
          };
          guacTunnel.onstatechange = (state: Guacamole.Tunnel.State) => {
            console.log('Guacamole Tunnel State =', state);
            setGuacamoleTunnelState(state);
            if (state === Guacamole.Tunnel.State.CLOSED) {
              console.log('Guacamole Tunnel Disconnected');
              //check if no error message is set and connection is established before disconnect then set error message
              if (
                !connectionError &&
                clientConnectedState !== Guacamole.Client.State.CONNECTED
              ) {
                connectionError =
                  'Something went wrong. Guacamole disconnected.';
                setErrorMessage(connectionError);
              }
              handleDisconnect();
            }
          };
          const handleDisconnect = async () => {
            if (display.hasChildNodes()) display.replaceChildren();
            if (clientConnectedState === Guacamole.Client.State.CONNECTED)
              guac?.disconnect();
            //close connection
            if (!isConnectionClosed) {
              isConnectionClosed = true;
              await closeConnection(
                inGateId,
                inInboundPort,
                inAccessSessionId,
                connectionError,
              );
            }
          };
          const handleError = (error: Guacamole.Status) => {
            console.error(
              deviceAccessErrors[error.code] ?? 'Something went wrong...',
            );
            connectionError =
              deviceAccessErrors[error.code] ??
              error.message ??
              'Something went wrong...';
            setErrorMessage(connectionError);
          };
          // Error handler
          guac.onerror = function (error) {
            handleError(error);
          };
          guacTunnel.onerror = function (error) {
            handleError(error);
          };
          // Connect
          guac.connect(' ');
        }
      }, 1000);
    },
    [closeConnection, disconnectFromCanvas, enableCopyFromRemoteClipboard],
  );

  const getToken = useCallback(
    async (connectionSettings: DeviceAccess) => {
      try {
        setErrorMessage('');
        dispatch(
          setLoader({
            loaderMessage: 'Please wait connecting...',
            openLoader: true,
          }),
        );
        const user = getLoggedInUser();
        console.log('getToken api called');
        const apiUrl = `devices/${device.deviceId}/getToken`;
        const tokenResponse = await apiClient.post(apiUrl, {
          ...connectionSettings,
          userId: user.userId,
          loginSessionId: user.loginSessionId,
        });
        const tokenResponseData = tokenResponse.data.data;
        const token = tokenResponseData.token;
        setAccessSessionId(tokenResponseData.accessSessionId);
        setOpenDialog(true);
        dispatch(
          setLoader({
            loaderMessage: 'Please wait connecting...',
            openLoader: false,
          }),
        );
        //connect to guacamole server
        const webSocketBaseURL =
          process.env.REACT_APP_USE_LOCAL_WEB_SOCKET_URL?.toLowerCase() ===
          'yes'
            ? process.env.REACT_APP_WEB_SOCKET_URL ??
              constants.DEFAULT_WEB_SOCKET_URL
            : `${process.env.REACT_APP_WEB_SOCKET_PROTOCOL ?? 'ws'}://${
                connectionSettings.siteName
              }-rc:${constants.DEFAULT_WEB_SOCKET_PORT}`;
        connectToDevice(
          token,
          tokenResponseData.gateId ?? -1,
          tokenResponseData.inboundPort ?? -1,
          webSocketBaseURL,
          tokenResponseData.accessSessionId,
        );
        return token;
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: 'Please wait connecting...',
            openLoader: false,
          }),
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        setErrorMessage(errorData);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: 'error',
          }),
        );
        //if dialog then close
        if (!isHideDialog) {
          onClose();
        }
      }
    },
    [connectToDevice, device.deviceId, dispatch, isHideDialog, onClose],
  );

  React.useEffect(() => {
    if (isRunEffect.current) {
      isRunEffect.current = false;
      if (device.accessProfile) {
        getToken({
          connectionProfile: device.accessProfile,
          type: Protocol.SSH,
          endTime: accessEndTime.endTime,
          siteId: device.siteId,
          siteName: device.siteName,
        });
      }
    }
  }, [
    accessEndTime.endTime,
    device.accessProfile,
    device.siteId,
    device.siteName,
    getToken,
  ]);

  const handleCloseCountDownPopup = () => {
    setCountDownOpen(false);
  };

  const handleTimeUp = () => {
    setCountDownOpen(true);
  };

  useSessionManagement({
    accessSessionId,
    disconnectGuacamoleClient,
    guacamoleClientState,
    deviceId: device.deviceId,
  });

  return isHideDialog ? (
    <Card sx={{ overflow: 'auto', minHeight: '100vh' }}>
      <CardHeader
        sx={{ paddingTop: 1, paddingBottom: 0 }}
        titleTypographyProps={{ textAlign: 'center' }}
        title={
          <>
            <Typography component="span" sx={{ fontSize: 16 }}>
              {`Connect to device: '${device.name}' IP: ${device.accessProfile?.deviceIP} using ${device.accessProfile?.name} account`}
            </Typography>
            <br />
            {isAdminUser() ? (
              <AdminAccessCountdown deviceTimezone={device.timezone} />
            ) : (
              <CountdownDialog
                open={false}
                countDownTime={accessEndTime.countDownTime}
                handleDisconnectOkayClick={onDisconnect}
                onClose={handleCloseCountDownPopup}
                deviceTimezone={device.timezone}
                onTimeUp={handleTimeUp}
                isShowOnlyCountdown={
                  guacamoleClientState === Guacamole.Client.State.CONNECTED
                }></CountdownDialog>
            )}
            {fileSystemObject &&
              guacamoleClientState === Guacamole.Client.State.CONNECTED &&
              guacamoleTunnelState === Guacamole.Tunnel.State.OPEN &&
              guacamoleClient && (
                <FileTransferComponent
                  guacamoleClient={guacamoleClient}
                  protocol={'ssh'}
                  fileSystemObject={fileSystemObject}
                  saveLogEvent={saveLogEvent}></FileTransferComponent>
              )}
          </>
        }
        action={
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={onDisconnect}>
            {'Close'}
          </Button>
        }
      />
      <CardContent sx={{ minHeight: '85vh' }} id="container">
        <Grid container justifyContent="center" alignItems="center">
          {/* Loading State */}
          {(guacamoleTunnelState === Guacamole.Tunnel.State.CONNECTING ||
            guacamoleClientState === Guacamole.Client.State.CONNECTING ||
            guacamoleClientState === Guacamole.Client.State.WAITING) &&
            guacamoleTunnelState !== Guacamole.Tunnel.State.CLOSED && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          {/* Error State */}
          {(guacamoleClientState === Guacamole.Client.State.DISCONNECTED ||
            guacamoleTunnelState === Guacamole.Tunnel.State.CLOSED) && (
            <Grid item>
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}>
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={onDisconnect}
                      id="cancel">
                      {'Cancel'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onReconnectSSH}>
                      {'ReConnect'}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
        <div id="SSHdisplay" tabIndex={-1} style={{ outline: 'none' }}></div>
        {isEndUser() && (
          <CountdownDialog
            open={countDownOpen}
            countDownTime={accessEndTime.countDownTime}
            onClose={handleCloseCountDownPopup}
            handleDisconnectOkayClick={onDisconnect}
            deviceTimezone={device.timezone}
            onTimeUp={handleTimeUp}></CountdownDialog>
        )}
      </CardContent>
    </Card>
  ) : (
    <Dialog
      open={open && isOpenDialog}
      fullWidth
      PaperProps={{
        style: {
          minHeight: '95vh',
          minWidth: '70vw',
        },
      }}>
      <DialogTitle sx={{ m: 0, p: 1, pb: 0 }} textAlign={'center'}>
        <Typography component="span" sx={{ fontSize: 16 }}>
          {`Connect to device: '${device.name}' IP: ${device.accessProfile?.deviceIP} using ${device.accessProfile?.name} account`}
        </Typography>
        <br />
        {isAdminUser() ? (
          <AdminAccessCountdown deviceTimezone={device.timezone} />
        ) : (
          <CountdownDialog
            open={false}
            countDownTime={accessEndTime.countDownTime}
            handleDisconnectOkayClick={onDisconnect}
            onClose={handleCloseCountDownPopup}
            deviceTimezone={device.timezone}
            onTimeUp={handleTimeUp}
            isShowOnlyCountdown={
              guacamoleClientState === Guacamole.Client.State.CONNECTED
            }></CountdownDialog>
        )}
        {fileSystemObject &&
          guacamoleClient &&
          guacamoleClientState === Guacamole.Client.State.CONNECTED &&
          guacamoleTunnelState === Guacamole.Tunnel.State.OPEN && (
            <FileTransferComponent
              guacamoleClient={guacamoleClient}
              protocol={'ssh'}
              saveLogEvent={saveLogEvent}
              fileSystemObject={fileSystemObject}></FileTransferComponent>
          )}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onDisconnect}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent id="container" sx={{ maxHeight: '80vh', pb: 0, pt: 1 }}>
        <Grid container justifyContent="center" alignItems="center">
          {/* Loading State */}
          {(guacamoleTunnelState === Guacamole.Tunnel.State.CONNECTING ||
            guacamoleClientState === Guacamole.Client.State.CONNECTING ||
            guacamoleClientState === Guacamole.Client.State.WAITING) &&
            guacamoleTunnelState !== Guacamole.Tunnel.State.CLOSED && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          {/* Error State */}
          {(guacamoleClientState === Guacamole.Client.State.DISCONNECTED ||
            guacamoleTunnelState === Guacamole.Tunnel.State.CLOSED) && (
            <Grid item>
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}>
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={onDisconnect}
                      id="cancel">
                      {'Cancel'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onReconnectSSH}>
                      {'ReConnect'}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
        <div id="SSHdisplay" tabIndex={-1} style={{ outline: 'none' }}></div>
        {isEndUser() && (
          <CountdownDialog
            open={countDownOpen}
            countDownTime={accessEndTime.countDownTime}
            handleDisconnectOkayClick={onDisconnect}
            onClose={handleCloseCountDownPopup}
            deviceTimezone={device.timezone}
            onTimeUp={handleTimeUp}></CountdownDialog>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={onDisconnect}>
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoteAccessSSH;
