import { useCallback, useEffect, useState } from 'react';
import Guacamole from 'guacamole-common-js';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Grid,
  Tooltip,
  IconButton,
  Box,
  LinearProgress,
  Typography,
  Portal,
  Backdrop,
  Theme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { setSnackbarToast } from 'redux/UiStateSlice';

interface Props {
  guacamoleClient: Guacamole.Client;
  fileSystemObject: Guacamole.Object;
  protocol: string;
  saveLogEvent: (information: string) => Promise<void>;
}

const FileTransferComponent: React.FC<Props> = ({
  guacamoleClient,
  fileSystemObject,
  protocol,
  saveLogEvent,
}) => {
  const dispatch = useDispatch();
  const [filePath, setFilePath] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);
  const [fileName, setFileName] = useState<string>('');

  const formatBytes = (bytes: number): string => {
    if (bytes < 1024 * 1024) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else if (bytes < 1024 * 1024 * 1024) {
      return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    } else {
      return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
  };

  const handleError = useCallback(
    (message: string) => {
      console.error(message);
      setUploadProgress(null);
      setDownloadProgress(null);
      setFileName('');
      dispatch(setSnackbarToast({ message, open: true, severity: 'error' }));
    },
    [dispatch],
  );

  const handleFileUpload = useCallback(
    (files: FileList | null) => {
      if (!files || files.length === 0) {
        handleError('No files selected for upload.');
        return;
      }
      Array.from(files).forEach((file) => {
        const mimetype = file.type || 'application/octet-stream';
        let stream: Guacamole.OutputStream;
        try {
          const streamName = file.name;
          stream = guacamoleClient.createFileStream(mimetype, streamName);
        } catch (error) {
          handleError(`Failed to start upload for "${file.name}"`);
          return;
        }
        const CHUNK_SIZE = 1024 * 10;
        let offset = 0;
        setFileName(file.name);
        stream.onack = (status: Guacamole.Status) => {
          console.log(`onack ${status.message} ${status.code}`);
          if (status.isError()) {
            handleError(
              `File upload failed for "${file.name}" Error: ${status.message}`,
            );
            return;
          }
          const uploadNextChunk = () => {
            const reader = new FileReader();
            const chunk = file.slice(offset, offset + CHUNK_SIZE);
            reader.onload = (event: ProgressEvent<FileReader>) => {
              if (!event.target?.result) {
                console.error('Error reading file chunk.');
                return;
              }
              const chunkContent = event.target.result as ArrayBuffer;
              console.log(
                `Uploading chunk of size: ${chunkContent.byteLength} bytes`,
              );
              const chunkBlob = new Blob([chunkContent]);
              const writer = new Guacamole.BlobWriter(stream);
              writer.oncomplete = () => {
                offset += CHUNK_SIZE;
                const progress = Math.min((offset / file.size) * 100, 100);
                console.log(`Uploading ${file.name} ${progress}%`);
                setUploadProgress(progress);
                if (offset < file.size) {
                  uploadNextChunk();
                } else {
                  dispatch(
                    setSnackbarToast({
                      message: `File "${file.name}" uploaded successfully.`,
                      open: true,
                      severity: 'success',
                    }),
                  );
                  setUploadProgress(null);
                  setFileName('');
                  saveLogEvent(`File "${file.name}" uploaded to remote device`);
                }
              };
              writer.sendBlob(chunkBlob);
            };
            reader.onerror = function () {
              console.error('Error reading file chunk.');
              setUploadProgress(null);
              setFileName('');
              dispatch(
                setSnackbarToast({
                  message: `Error reading file ${file.name}.`,
                  open: true,
                  severity: 'error',
                }),
              );
            };
            reader.readAsArrayBuffer(chunk);
          };
          uploadNextChunk();
        };
      });
    },
    [handleError, dispatch, guacamoleClient, saveLogEvent],
  );

  const handleFileDownload = useCallback(
    (stream: Guacamole.InputStream, filename: string, mimetype: string) => {
      stream.sendAck('Ready', Guacamole.Status.Code.SUCCESS);
      const blobStream = new Guacamole.BlobReader(stream, mimetype);
      let receivedBytes = 0;
      setFileName(filename);
      blobStream.onprogress = (length) => {
        if (length > 0) {
          receivedBytes += length;
          setDownloadProgress(receivedBytes);
        }
      };
      // When all data is received, create a downloadable file
      blobStream.onend = () => {
        const blob = blobStream.getBlob();
        const url = URL.createObjectURL(blob);
        // Trigger download in the browser
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        setFilePath('');
        setDownloadProgress(null);
        setFileName('');
        dispatch(
          setSnackbarToast({
            message: `File "${filename}" downloaded`,
            open: true,
            severity: 'success',
          }),
        );
        saveLogEvent(`File "${filename}" downloaded from remote device`);
      };
    },
    [saveLogEvent, dispatch],
  );

  const enableFileDownload = useCallback(
    (guacamoleClient: Guacamole.Client) => {
      guacamoleClient.onfile = function (stream, mimetype, filename) {
        handleFileDownload(stream, filename, mimetype);
      };
    },
    [handleFileDownload],
  );

  useEffect(() => {
    if (guacamoleClient) {
      enableFileDownload(guacamoleClient);
    }
  }, [enableFileDownload, guacamoleClient]);

  const getFormattedPath = (protocol: string, filePath: string): string => {
    if (protocol === 'rdp') {
      return filePath.startsWith('\\') ? filePath : `\\${filePath}`;
    } else {
      return filePath.startsWith('/') ? filePath : `/${filePath}`;
    }
  };

  const initiateFileTransfer = useCallback(() => {
    if (!fileSystemObject) {
      console.error('fileSystemObject client is not initialized.');
      return;
    }
    const enteredPath = filePath?.trim();
    if (!enteredPath) {
      dispatch(
        setSnackbarToast({
          message: 'Please enter a valid file path.',
          open: true,
          severity: 'error',
        }),
      );
      return;
    }
    try {
      const path = getFormattedPath(protocol, enteredPath);
      fileSystemObject.requestInputStream(
        path,
        function downloadStreamReceived(stream, mimetype) {
          const filename =
            filePath.split(/(\\|\/)/g).pop() || 'downloaded_file';
          handleFileDownload(stream, filename, mimetype);
        },
      );
    } catch (error) {
      console.error('Error initiating file download:', error);
    }
  }, [dispatch, filePath, fileSystemObject, handleFileDownload, protocol]);

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      textAlign="center">
      <Grid item xs={6}>
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: 1,
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          <TextField
            label="File path to download from the remote device"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="File path to download from the remote device"
            value={filePath}
            onChange={(e) => setFilePath(e.target.value)}
          />
          <Button
            variant="contained"
            color="info"
            size="small"
            onClick={initiateFileTransfer}>
            Download
          </Button>
          <Tooltip title="Enter the correct file path to download from the remote device">
            <IconButton>
              <InfoOutlinedIcon color="info" />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item sx={{ marginLeft: 2 }}>
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: 1,
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          <Button
            variant="contained"
            component="label"
            size="small"
            color="info">
            Upload File
            <input
              type="file"
              onChange={(event) => handleFileUpload(event.target.files)}
              hidden
            />
          </Button>
          <Tooltip title="Select a file from your local system to upload.">
            <IconButton>
              <InfoOutlinedIcon color="info" />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Portal>
        <Backdrop
          sx={{
            zIndex: (theme: Theme) => theme.zIndex.modal + 1,
            color: (theme: Theme) => theme.palette.background.default,
          }}
          onClick={(e) => e.stopPropagation()}
          open={uploadProgress !== null || downloadProgress !== null}>
          {uploadProgress !== null && (
            <Box sx={{ mt: 1 }} onClick={(e) => e.stopPropagation()}>
              <Typography variant="body1">
                Uploading {fileName} in progress {uploadProgress.toFixed(2)}%
              </Typography>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}
          {downloadProgress !== null && (
            <Box sx={{ mt: 1 }} onClick={(e) => e.stopPropagation()}>
              <Typography variant="body1">
                Downloading {fileName}. Received {formatBytes(downloadProgress)}
              </Typography>
              <LinearProgress
                variant="indeterminate"
                value={downloadProgress}
              />
            </Box>
          )}
        </Backdrop>
      </Portal>
    </Grid>
  );
};

export default FileTransferComponent;
