import { LogEvent } from '../types';

export const SampleLogsForChart = [
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T00:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T01:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T01:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T01:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T01:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T01:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T04:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T05:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T06:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T07:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T08:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T09:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T08:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T08:18:03.425Z',
  },
  {
    _id: '673460ce7df58ceaf26a5bc9',
    logId: '2ef55fdf-36fe-44d3-8707-50eb95584f55',
    severity: 'Medium',
    logEventType: 'Wrong Password',
    createdOn: '2024-11-21T09:18:22.702Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T09:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T09:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T09:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T09:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T09:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T09:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T09:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T09:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T10:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T10:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T10:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T10:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T10:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf4',
    severity: 'High',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T10:54:02.916Z',
  },
  {
    _id: '673b0fa13030cfb00e987cf2',
    severity: 'Info',
    logEventType: 'SSH Connection',
    createdOn: '2024-11-21T10:54:02.916Z',
  },
  {
    _id: '673ef613eead4a06212e221d',
    severity: 'Info',
    logEventType: 'Device Updated',
    createdOn: '2024-11-21T11:53:01.034Z',
  },
  {
    _id: '673ef6be0d77e759a76842ae',
    severity: 'Info',
    logEventType: 'Device Updated',
    createdOn: '2024-11-21T11:00:13.670Z',
  },
  {
    _id: '67402327255a847069c858f1',
    severity: 'Info',
    logEventType: 'Device Updated',
    createdOn: '2024-11-21T12:22:31.462Z',
  },
  {
    _id: '6744182a5b591c774c24ad43',
    severity: 'Info',
    logEventType: 'Site Updated',
    createdOn: '2024-11-21T13:24:42.697Z',
  },
  {
    _id: '674448b013a2a07291e510d3',
    severity: 'Info',
    logEventType: 'User Updated',
    createdOn: '2024-11-21T14:51:44.216Z',
  },
  {
    _id: '67444a2513a2a07291e510fb',
    severity: 'Info',
    logEventType: 'Logout',
    createdOn: '2024-11-21T15:57:57.244Z',
  },
  {
    _id: '67444a2613a2a07291e51101',
    severity: 'Info',
    logEventType: 'Login - Success',
    information: 'username: nilimas',
    createdOn: '2024-11-21T16:57:58.924Z',
  },
  {
    _id: '67444a2613a2a07291e51101',
    severity: 'Info',
    logEventType: 'Login - Success',
    information: 'username: nilimas',
    createdOn: '2024-11-21T16:57:58.924Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T17:18:03.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T18:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T18:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T18:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T19:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T20:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T21:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T22:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T23:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T23:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    logId: '03bddd3d-d187-4b1b-a425-656c69fb96c4',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T22:00:00.425Z',
  },
  {
    _id: '673460bb7df58ceaf26a5bc5',
    severity: 'Warning',
    logEventType: 'Unknown User',
    createdOn: '2024-11-21T23:00:00.425Z',
  },
] as unknown as LogEvent[];

export const SampleDemoLineChart = [
  { x: 'Label 1', y: 30 },
  { x: 'Label 2', y: 37 },
  { x: 'Label 3', y: 42 },
  { x: 'Label 4', y: 44 },
  { x: 'Label 5', y: 48 },
  { x: 'Label 6', y: 51 },
  { x: 'Label 7', y: 55 },
  { x: 'Label 8', y: 58 },
  { x: 'Label 9', y: 62 },
  { x: 'label 10', y: 69 },
];
