import React from 'react';

import {
  Breadcrumbs,
  Card,
  Container,
  CssBaseline,
  Unstable_Grid2 as Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import LogEventsChart from './sections/LogEventsChart';
import DemoLineChart from './sections/DemoLineChart';
import {
  SampleDemoLineChart,
  // SampleLogsForChart,
} from './sections/SampleLogsForChart';

const useStyles = makeStyles(() => ({
  contentSection: {
    padding: '4px',
    border: '1px solid #0000111E',
    marginTop: '10px',
    paddingTop: '20px',
  },
  contentPadding: {
    padding: '2px',
  },
}));

const DashboardDefaultPage = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography variant="body1">{'Dashboard'}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <Container maxWidth="xl">
          {/* <Grid container spacing={3}>
            <LogEventsChart chartsData={SampleLogsForChart}></LogEventsChart>
          </Grid> */}
          <Grid container spacing={3}>
            <DemoLineChart
              chartsData={SampleDemoLineChart}
              themeMode="light"></DemoLineChart>
          </Grid>
        </Container>
      </Card>
    </React.Fragment>
  );
};

export default DashboardDefaultPage;
