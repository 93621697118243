import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { RouteType } from '../../routes/types';

type Props = {
  item: RouteType;
  onClick: () => void;
};

const PopoverSidebarItem: React.FC<Props> = ({ item, onClick }) => {
  const { appState } = useSelector((state: RootState) => state.appState);

  return item.sidebarProps && item.path ? (
    <>
      <ListItemButton
        component={Link}
        to={item.path}
        onClick={onClick}
        sx={{
          '&:hover': {
            backgroundColor: 'secondary.light',
          },
          borderLeft: appState === item.state ? '4px solid' : '0px',
          borderLeftColor: appState === item.state ? 'secondary.main' : 'unset',
          paddingY: 0.5,
          paddingX: 2,
        }}>
        <ListItemIcon
          sx={{
            color: appState === item.state ? 'secondary.main' : 'unset',
            marginRight: -1,
          }}>
          {item.sidebarProps.icon ?? null}
        </ListItemIcon>
        <ListItemText
          primary={
            item.sidebarProps.popoverDisplayText ??
            item.sidebarProps.displayText
          }
          sx={{
            color: appState === item.state ? 'secondary.main' : 'unset',
            marginLeft: -1,
          }}
        />
      </ListItemButton>
    </>
  ) : null;
};

export default PopoverSidebarItem;
