import React from 'react';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import apiClient from 'common/apiClientAxios';
import { DeviceGroup, DeviceGroupNameForm } from '../types';
import TcTextField from 'common/components/TcTextField';
import { constants } from 'common/constants';
import DeleteGroup from './DeleteDeviceGroup';

type AddDeviceGroupProps = {
  onSaveGroup: (formData: DeviceGroupNameForm) => void;
  onCancelGroup: () => void;
  formData?: DeviceGroupNameForm;
  isEdit?: boolean;
  isShowCancel?: boolean;
  group?: DeviceGroup;
};

const AddDeviceGroup: React.FC<AddDeviceGroupProps> = (props) => {
  const dispatch = useDispatch();
  const methods = useForm<DeviceGroupNameForm>({
    defaultValues: { name: props.formData?.name ?? '' },
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSaveGroup = handleSubmit(async (data: DeviceGroupNameForm) => {
    props.onSaveGroup(data);
  });
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const onDeleteGroup = async () => {
    try {
      if (props.group?.groupId) {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          }),
        );
        const groupDeleteResponse = await apiClient.delete(
          `/devices-groups/${props.group?.groupId}`,
        );
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
        dispatch(
          setSnackbarToast({
            message: groupDeleteResponse.data.meta.message,
            open: true,
            severity: 'success',
          }),
        );
        props.onCancelGroup();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
      setOpenDialogue(false);
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}></Grid>
            <Grid item>
              <Typography variant="body1">
                <strong>Device Group:</strong>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TcTextField
                name="name"
                variant="outlined"
                fullWidth
                rules={{
                  required: 'Name is required',
                  pattern: {
                    value: constants.GROUP_NAME_REGEX,
                    message: 'Invalid name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onSaveGroup}
                fullWidth
                variant="contained"
                color="info">
                {props.isEdit ? 'Update Group' : 'Create Group'}
              </Button>
            </Grid>
            {props.isEdit && (
              <Grid item>
                <Button
                  size="small"
                  onClick={() => setOpenDialogue(true)}
                  fullWidth
                  variant="outlined"
                  color="info">
                  Delete Group
                </Button>
              </Grid>
            )}
            {props.isShowCancel && (
              <Grid item>
                <Button
                  size="small"
                  onClick={props.onCancelGroup}
                  fullWidth
                  color="info"
                  variant="outlined">
                  {'Cancel'}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
      {openDialogue && props.group && (
        <DeleteGroup
          onDeleteGroup={onDeleteGroup}
          selectedGroup={props.group}
          open={openDialogue}
          onCancelRemove={() => setOpenDialogue(false)}></DeleteGroup>
      )}
    </React.Fragment>
  );
};

export default AddDeviceGroup;
