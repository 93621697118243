import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { DeviceConnectionProfileForm } from '../types';
import {
  AuthMethod,
  DomainRegistered,
  HostnameType,
  Protocol,
} from 'common/enums';
import DeleteProfileConfirm from './DeleteProfileConfirm';

type ConnectionProfileProps = {
  connectionProfile: DeviceConnectionProfileForm;
  localIP: string;
  onClickEdit: (profile: DeviceConnectionProfileForm) => void;
  onClickDelete: () => void;
};

const ConnectionProfile: React.FC<ConnectionProfileProps> = ({
  connectionProfile,
  onClickEdit,
  onClickDelete,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [showDeleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const showDeleteConfirmationDialog = () => {
    setDeleteConfirmation(true);
  };

  const authTypeMap = {
    no_auth: 'No Credentials',
    manual: 'Manual Credentials',
    profile: 'Authentication from Profile',
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange}
      sx={{
        marginRight: 1,
        border: '1px solid #00001161', // Change this to your desired border style
        borderRadius: '8px',
        boxShadow: 'none',
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panel-header"
        sx={{ paddingRight: 2 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography>{connectionProfile.name}</Typography>
          </Grid>

          {!expanded && (
            <Grid item>
              <Button
                size="small"
                variant="text"
                color="info"
                onClick={() => onClickEdit(connectionProfile)}>
                {'Edit'}
              </Button>
              <Button
                size="small"
                variant="text"
                color="info"
                onClick={showDeleteConfirmationDialog}>
                {'Delete'}
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography>
              Domain Registered: {connectionProfile.domainRegistered}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Local Access Profile: {connectionProfile.localAccessProfile}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Protocol: {connectionProfile.protocol}</Typography>
          </Grid>
          {connectionProfile.hostnameType &&
          connectionProfile.protocol.includes(Protocol.HTTP) ? (
            <>
              {connectionProfile.hostnameType === HostnameType.IP ? (
                <Grid item>
                  <Typography>
                    Device local IP: {connectionProfile.deviceIP}
                  </Typography>
                </Grid>
              ) : (
                connectionProfile.urls?.map((url, index) => (
                  <Grid item key={`${url}-${index}`}>
                    <Typography>
                      URL {index + 1}: {url}
                    </Typography>
                  </Grid>
                ))
              )}
            </>
          ) : (
            <>
              <Grid item>
                <Typography>
                  Device local IP: {connectionProfile.deviceIP}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>Service Port: {connectionProfile.port}</Typography>
              </Grid>
            </>
          )}
          {connectionProfile.authType &&
            connectionProfile.domainRegistered === DomainRegistered.NO && (
              <Grid item>
                <Typography>
                  Authentication type: {authTypeMap[connectionProfile.authType]}
                </Typography>
              </Grid>
            )}
          {connectionProfile.authenticationMethod && (
            <Grid item>
              <Typography>
                Authentication method: {connectionProfile.authenticationMethod}
              </Typography>
            </Grid>
          )}
          {connectionProfile.username && (
            <Grid item>
              <Typography>Username: {connectionProfile.username}</Typography>
            </Grid>
          )}
          {connectionProfile.authenticationMethod === AuthMethod.KEY ? (
            <Grid item>
              <Typography>Key: {connectionProfile.keyFileName}</Typography>
            </Grid>
          ) : (
            connectionProfile.password && (
              <Grid item>
                <Typography>Password: ************</Typography>
              </Grid>
            )
          )}
          {expanded && (
            <Grid item alignSelf="flex-end">
              <Button
                size="small"
                variant="text"
                color="info"
                onClick={() => onClickEdit(connectionProfile)}>
                {'Edit'}
              </Button>
              <Button
                size="small"
                variant="text"
                color="info"
                onClick={showDeleteConfirmationDialog}>
                {'Delete'}
              </Button>
            </Grid>
          )}
        </Grid>
        <DeleteProfileConfirm
          open={showDeleteConfirmation}
          selectedProfile={connectionProfile}
          onConfirmDelete={onClickDelete}
          onCancelRemove={() =>
            setDeleteConfirmation(false)
          }></DeleteProfileConfirm>
      </AccordionDetails>
    </Accordion>
  );
};

export default ConnectionProfile;
