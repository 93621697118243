import * as React from 'react';
import { useEffect } from 'react';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { constants } from 'common/constants';
import CustomToolbar from './CustomToolbar';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import {
  LogEvent,
  LogsFilterData,
  LogsFilterMasterData,
  PaginationQueryModel,
} from './types';
import LogsFilterChipDisplay from './LogsFilterChipDisplay';

type LogsTableProps = {
  data: LogEvent[];
  totalRows: number;
  paginationQueryModel: PaginationQueryModel;
  openFilter: () => void;
  onPaginationModelChange: (
    model: GridPaginationModel,
    details: GridCallbackDetails<any>,
  ) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
  handleExportClick: (format: string) => void;
  handleViewInfoClick: (logEvent: LogEvent) => void;
  logsMasterData: LogsFilterMasterData;
  handleApplyFilter: (filters: LogsFilterData) => void;
  showFilter: boolean;
  searchValue: string;
  handleSearchChange: (searchText: string) => void;
};
dayjs.extend(utc);
const LogsTable: React.FC<LogsTableProps> = (props) => {
  const [rows, setRows] = React.useState<LogEvent[]>(props.data);

  const handleFilterClick = () => {
    props.openFilter();
  };

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const columns: GridColDef[] = [
    {
      field: 'createdOn',
      headerName: 'Event Time',
      flex: 1,
      sortable: true,
      valueFormatter: (value) => {
        return dayjs(new Date(value)).utc().format('MM/DD/YYYY, hh:mm A');
      },
    },
    {
      field: 'severity',
      headerName: 'Severity',
      width: 100,
      sortable: false,
    },
    {
      field: 'logEventType',
      headerName: 'Log Event',
      flex: 1,
      sortable: false,
    },
    {
      field: 'information',
      headerName: 'Information',
      display: 'flex',
      width: 300,
      sortable: false,
      renderCell: (params) => {
        const MAX_LENGTH = 60;
        const text = params.row.information || '';

        const isLongText = text.length > MAX_LENGTH;
        const displayText = isLongText
          ? text.slice(0, MAX_LENGTH) + '...'
          : text;

        return (
          <Box
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}>
            <Link
              component="button"
              sx={{
                color: (theme) => theme.palette.info.main,
                textDecorationColor: (theme) => theme.palette.info.main,
                // marginLeft: 0.5,
                textAlign: 'left',
              }}
              onClick={() => props.handleViewInfoClick(params.row)}>
              <Typography variant="body2" component="span">
                {displayText}
              </Typography>
            </Link>
          </Box>
        );
      },
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      sortable: false,
      renderCell: (params) => <>{params.row.username ?? params.row.email}</>,
    },
    {
      field: 'siteName',
      headerName: 'Site',
      flex: 1,
      sortable: false,
    },
    {
      field: 'deviceName',
      headerName: 'Device',
      flex: 1,
      sortable: false,
    },
    {
      field: 'sessionIp',
      headerName: 'IP Address',
      width: 145,
      sortable: false,
    },
    {
      field: 'sessionId',
      headerName: 'Session ID',
      width: 100,
      sortable: false,
    },
    {
      field: 'adminUsername',
      headerName: 'User2',
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(row) => row._id}
      paginationMode={'server'}
      rowCount={props.totalRows}
      pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
      onPaginationModelChange={props.onPaginationModelChange}
      sortingMode="server"
      onSortModelChange={props.onSortModelChange}
      paginationModel={props.paginationQueryModel}
      initialState={{
        pagination: { paginationModel: constants.PAGINATION_MODEL },
        columns: {
          columnVisibilityModel: {
            adminUsername: false,
          },
        },
      }}
      slots={{
        toolbar: () => (
          <>
            <CustomToolbar
              handleFilterClick={handleFilterClick}
              handleExportClick={props.handleExportClick}
              searchText={props.searchValue}
              handleSearchChange={props.handleSearchChange}
            />
            {!props.showFilter && (
              <LogsFilterChipDisplay
                logsMasterData={props.logsMasterData}
                filters={props.paginationQueryModel.filters}
                handleUpdatedFilter={props.handleApplyFilter}
                openFilter={props.openFilter}
              />
            )}
          </>
        ),
        noRowsOverlay: () => <NoRowsOverlay name="Logs" hasAccess={true} />,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '600',
        },
        '& .MuiDataGrid-virtualScroller': {
          overflow: 'scroll',
          maxHeight: '650px',
        },
      }}
      disableRowSelectionOnClick
    />
  );
};

export default LogsTable;
