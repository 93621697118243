import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CssBaseline,
  Card,
  Link,
  Typography,
  CardHeader,
  Drawer,
  Box,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { useDispatch } from 'react-redux';

import apiClient from 'common/apiClientAxios';
import { DrawerHeader } from 'common/styles/styledComponents';
import { useContentStyles } from 'common/styles/useContentStyles';
import { constants } from 'common/constants';
import {
  LogEvent,
  LogsFilterData,
  LogsFilterMasterData,
  LogsQuery,
  PaginationQueryModel,
} from './types';
import LogsFilter from './LogsFilter';
import LogsTable from './LogsTable';
import LogDetails from './LogDetails';

const Logging: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const isRunEffect = useRef(true);
  const [logsFilterMasterData, setLogsFilterMasterData] =
    useState<LogsFilterMasterData>({
      eventTypes: [],
      severity: [],
      sites: [],
      users: [],
    });
  const defaultFilterValues = useMemo(
    () => ({
      eventTypes: [],
      sessionIp: '',
      userIp: '',
      siteIds: [],
      severity: [],
      userIds: [],
      startDate: null,
      endDate: null,
    }),
    [],
  );
  const [logs, setLogs] = useState<LogEvent[]>([]);
  const [paginationQueryModel, setPaginationQueryModel] =
    useState<PaginationQueryModel>({
      page: 0,
      pageSize: 15,
      filters: defaultFilterValues,
      field: 'createdOn',
      sort: 'desc' as 'asc' | 'desc',
    });
  const [totalRows, setTotalRows] = useState<number>(0);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [openLogView, setOpenLogView] = React.useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState<number | undefined>(
    undefined,
  );
  const [selectedLogEvent, setSelectedLogEvent] = React.useState<LogEvent>();
  const handleApplyFilter = async (filterValues: LogsFilterData) => {
    setPaginationQueryModel({
      ...paginationQueryModel,
      filters: filterValues,
      page: 0,
    });
    setOpenFilter(false);
  };

  const handleClearFilter = async () => {
    setPaginationQueryModel({
      ...paginationQueryModel,
      filters: defaultFilterValues,
      page: 0,
    });
  };

  const handleCancelFilter = async () => {
    setOpenFilter(false);
  };

  const onPaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationQueryModel({
      ...paginationQueryModel,
      page: model.page,
      pageSize: model.pageSize,
    });
  };
  const handleSortModelChange = async (sortModel: GridSortModel) => {
    setPaginationQueryModel({
      ...paginationQueryModel,
      field: sortModel[0]?.field,
      sort: sortModel[0]?.sort || 'desc',
    });
  };
  const downloadFile = async (format: string) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const query: LogsQuery = {
        format,
        ...paginationQueryModel,
        limit: paginationQueryModel.pageSize,
        page: paginationQueryModel.page + 1,
      };
      const response = await apiClient.post('/logEvents/download', query, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], {
        type: format === 'csv' ? 'text/csv' : 'application/json',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `log-events.${format}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };
  const handleViewInfoClick = (logEvent: LogEvent) => {
    setSelectedLogEvent(logEvent);
    setOpenLogView(true);
  };
  const closeLogView = () => {
    setSelectedLogEvent(undefined);
    setOpenLogView(false);
  };
  const updateTable = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const query: LogsQuery = {
        page: paginationQueryModel.page + 1, // Server-side pagination is 1-based
        limit: paginationQueryModel.pageSize,
        order: paginationQueryModel.sort,
        sort: paginationQueryModel.field,
        searchText: paginationQueryModel.searchText,
        ...paginationQueryModel.filters,
      };
      const response = await apiClient.post('/logEvents', query);
      const { total, events } = response.data.data;
      setLogs(events);
      setTotalRows(total);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch, paginationQueryModel]);
  const getMasterData = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const formMasterResponse = await apiClient.get(`/logEvents/filterMaster`);
      const formMasters = formMasterResponse.data.data as LogsFilterMasterData;
      setLogsFilterMasterData(formMasters);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch]);
  const handleSearch = async (searchText: string) => {
    setSearchValue(searchText);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(async () => {
      setPaginationQueryModel({
        ...paginationQueryModel,
        searchText,
        page: 0,
      });
    }, 500);

    setDebounceTimeout(timeout as unknown as number);
  };
  useEffect(() => {
    if (isRunEffect.current) {
      getMasterData();
      isRunEffect.current = false;
    }
  }, [getMasterData, isRunEffect]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Logging
          </Link>
          <Typography variant="body1">{'Logging'}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Log Viewer"
          className={classes.cardHeader}
          action={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: (theme) => theme.palette.info.main,
              }}>
              <Typography>
                Event time displayed in: <strong>UTC</strong>
              </Typography>
            </Box>
          }></CardHeader>

        {openFilter && (
          <Card elevation={0} className={classes.contentSection}>
            <LogsFilter
              onApplyFilter={handleApplyFilter}
              handleClearFilter={handleClearFilter}
              handleCancelFilter={handleCancelFilter}
              filters={paginationQueryModel.filters}
              logsFilterMasterData={logsFilterMasterData}
            />
          </Card>
        )}
        <LogsTable
          data={logs}
          totalRows={totalRows}
          paginationQueryModel={paginationQueryModel}
          onPaginationModelChange={(model) => onPaginationModelChange(model)}
          onSortModelChange={handleSortModelChange}
          handleExportClick={downloadFile}
          handleViewInfoClick={handleViewInfoClick}
          handleApplyFilter={handleApplyFilter}
          logsMasterData={logsFilterMasterData}
          showFilter={openFilter}
          searchValue={searchValue}
          handleSearchChange={handleSearch}
          openFilter={() => setOpenFilter(true)}></LogsTable>
        {selectedLogEvent && (
          <Drawer
            anchor="right"
            open={openLogView}
            PaperProps={{ sx: { width: '60%' } }}>
            <DrawerHeader />
            <LogDetails
              onClose={closeLogView}
              logEvent={selectedLogEvent}></LogDetails>
          </Drawer>
        )}
      </Card>
    </React.Fragment>
  );
};

export default Logging;
