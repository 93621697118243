import React, { useCallback, useEffect, useState } from 'react';
import { CssBaseline, Card, Link, Typography, CardHeader } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useDispatch } from 'react-redux';
import { setSnackbarToast } from 'redux/UiStateSlice';
import { useNavigate } from 'react-router-dom';

import { useContentStyles } from 'common/styles/useContentStyles';
import apiClient from 'common/apiClientAxios';
import RolesTable from './RolesTable';
import { Role } from 'pages/settings/types';

const Roles: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [roles, setRoles] = useState<Role[]>([]);

  const updateTable = useCallback(async () => {
    try {
      const rolesResponse = await apiClient.get('/roles');
      setRoles(rolesResponse.data.data as Role[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch]);

  const handleAddUser = (obj: Role) => {
    navigate(`/portal/users/summary`, {
      state: {
        userRole: obj,
      },
    });
  };

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Resources
          </Link>
          <Typography variant="body1">{'Roles'}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Roles"
          className={classes.cardHeader}></CardHeader>
        <RolesTable data={roles} handleAddUser={handleAddUser}></RolesTable>
      </Card>
    </React.Fragment>
  );
};

export default Roles;
